import { Dot } from 'pure-react-carousel'
import React from 'react'

import { LabelInterface } from '@store/types'

export interface LabelDotInterface {
  item: LabelInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
  disabled?: boolean
}

function LabelDot({
  item,
  index,
  currentSlide,
  setCurrentSlide,
  disabled,
}: LabelDotInterface) {
  return (
    <Dot
      className={`ad-dot w-auto rounded px-4 py-2 text-sm font-semibold uppercase tracking-widest text-white shadow-lg drop-shadow-md transition-all ${
        currentSlide === index ? 'bg-white/10' : 'bg-white/30'
      }`}
      slide={index}
      onClick={() => setCurrentSlide(index)}
      disabled={disabled}
    >
      <span className="drop-shadow-70">{item.text}</span>
    </Dot>
  )
}

export default LabelDot

import React from 'react'

import { SvgProps } from './types'

function ArrowRefreshSvg({
  width,
  height,
  className,
  size,
  styles,
  fill,
  rotate,
  strokeColor,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || 17}
      height={height || 17}
      viewBox="0 0 17 18"
      fill={fill || 'none'}
      stroke={strokeColor || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_181_8047)">
        <path
          d="M14.9941 6.16675C14.4424 4.90341 13.5342 3.82843 12.3807 3.07353C11.2272 2.31864 9.8786 1.91663 8.50007 1.91675C4.82736 1.91675 1.80632 4.71254 1.45215 8.29175"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0415 6.16667H15.1582C15.214 6.16667 15.2692 6.15567 15.3208 6.13432C15.3724 6.11296 15.4192 6.08165 15.4587 6.04219C15.4981 6.00272 15.5294 5.95587 15.5508 5.90431C15.5722 5.85274 15.5832 5.79748 15.5832 5.74167V2.625M2.04053 11.8333C3.13419 14.3352 5.63036 16.0833 8.53453 16.0833C12.2079 16.0833 15.2283 13.2875 15.5832 9.70833"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.99382 11.8333H1.87715C1.76443 11.8333 1.65633 11.878 1.57663 11.9577C1.49693 12.0374 1.45215 12.1455 1.45215 12.2583V15.3749"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_181_8047">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowRefreshSvg

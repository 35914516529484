import React from 'react'

import { FilterSvg } from '@svg/react'

interface ComponentProps {
  filterCount: number
  popupState: boolean
  disable: boolean
  togglePopup: (arg: boolean) => void
}

function AddFilterButton({
  filterCount,
  popupState,
  disable = false,
  togglePopup,
}: ComponentProps) {
  return (
    <button
      type="button"
      className="relative rounded bg-mainColour px-3 py-1 text-xs text-white"
      onClick={() => {
        if (!disable) {
          togglePopup(!popupState)
        }
      }}
    >
      <div className="inline-flex items-center">
        <span className="text-xs font-bold tracking-widest">Add Filter</span>
        <FilterSvg className="ml-1 h-4 w-4" />
      </div>
      <span className="absolute -right-2 -top-2 h-5 w-5 rounded-full border-2 border-mainColour bg-white text-xs font-bold text-mainColour">
        {filterCount}
      </span>
    </button>
  )
}

export default AddFilterButton

import React from 'react'

function AreaSkeleton() {
  return (
    <div className="relative flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-secondaryColour">
      <div className="skeleton pill mb-[160px]"></div>
    </div>
  )
}

export default AreaSkeleton

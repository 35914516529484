import { fabric } from 'fabric'

import { Point, Size } from '@api/interactive-plan'

import { CustomObjectTypes, MarkerType, TransparentFill } from './constants'
import { getRelativePostition, getScaledAttribute } from './utils/utils'

export interface MarkerParams {
  markerColor?: string
  markerCoordinates: Point
  coordinates: Point
  bgSize: Size
  widthRatio: number
  heightRatio: number
  markerSize?: Size
  size: Size
  type?: string
  label: string
}

const Marker = (): {
  createMarker: (arg: MarkerParams) => fabric.Group
} => {
  const GroupBase = {
    originX: 'center',
    originY: 'center',
    hasBorders: false,
    hasControls: false,
    objectCaching: false,
  }

  const OuterCircleBase = {
    radius: 18,
    strokeWidth: 4,
    stroke: '#fff',
    scaleX: 1,
    scaleY: 1,
    objectCaching: false,
    transparentCorners: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
  }

  const InnerCircleBase = {
    radius: 5,
    fill: '#fff',
    strokeWidth: 0,
    scaleX: 1,
    scaleY: 1,
    objectCaching: false,
    transparentCorners: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
  }

  const TextObjectBase = {
    originX: 'center',
    originY: 'center',
    fontFamily: 'Helvetica Neue',
    fontSize: 1,
    objectCaching: false,
  }

  const setMarkerLabel = (label: string, type: string, markerWidth: number) => {
    if (type === MarkerType.badgeLabel) {
      const textObject = new fabric.Text(label.toString(), TextObjectBase)
      textObject.fontSize =
        ((textObject?.fontSize || 0) * (markerWidth || 0)) /
        ((textObject?.width || 0) + 1)
      textObject.width = textObject?.width || 0
      return textObject as fabric.Text
    }
    return undefined
  }

  const setOuterCircle = (markerColor: string, markerType?: string) =>
    new fabric.Circle({
      ...OuterCircleBase,
      name: CustomObjectTypes.outerCircle,
      stroke: markerColor || OuterCircleBase.stroke,
      fill:
        markerType === MarkerType.badgeLabel || markerType === MarkerType.arrow
          ? markerColor || '#ffffff'
          : TransparentFill,
    })

  const createMarker = ({
    size,
    coordinates,
    markerColor,
    markerCoordinates,
    bgSize,
    widthRatio,
    heightRatio,
    markerSize,
    type,
    label,
  }: MarkerParams) => {
    const outerCircle = setOuterCircle(markerColor || '', type)
    const innerCircle = new fabric.Circle({
      ...InnerCircleBase,
      name: CustomObjectTypes.innerCircle,
      fill: markerColor || InnerCircleBase.fill,
    })

    if (markerSize) {
      outerCircle.scaleToWidth(
        getScaledAttribute(markerSize.width, bgSize.width)
      )
      outerCircle.scaleToHeight(
        getScaledAttribute(markerSize.height, bgSize.height)
      )
      innerCircle.scaleToWidth(
        getScaledAttribute(markerSize.width, bgSize.width) * 0.3
      )
      innerCircle.scaleToHeight(
        getScaledAttribute(markerSize.height, bgSize.height) * 0.3
      )
    }

    if (!markerCoordinates) {
      outerCircle.opacity = 0
      innerCircle.opacity = 0
    }

    const markerGroup = new fabric.Group([outerCircle, innerCircle], {
      ...GroupBase,
      name: CustomObjectTypes.markerGroup,
      left: getRelativePostition(
        coordinates?.x || 0,
        markerCoordinates?.x || 0,
        size.width || 0,
        bgSize.width || 0
      ),
      top: getRelativePostition(
        coordinates?.y || 0,
        markerCoordinates?.y || 0,
        size.height || 0,
        bgSize.height || 0
      ),
    })

    const markerLabel = setMarkerLabel(
      label,
      type || '',
      markerGroup?.width || 0
    )

    if (markerLabel) {
      markerGroup.add(markerLabel)
    }

    if (!markerSize) {
      markerGroup.scale(Math.min(widthRatio, heightRatio) / 1.5)
    }

    return markerGroup
  }

  return {
    createMarker,
  }
}

export default Marker
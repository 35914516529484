import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'

function PageNotFound() {
  return (
    <Container
      className="flex items-center justify-center"
      background={{
        url: '/images/devsuite-bg.jpg',
        type: 'new',
        noSpliceUrl: true,
      }}
      disableNavigation
    >
      <div className="flex h-2/6 w-7/12 items-center justify-center rounded-xl bg-gray-300 bg-gradient-to-tr ">
        <div className="flex w-11/12 flex-col items-center gap-4 lg:w-9/12">
          <h2 className="mb-2 text-center text-4xl font-medium">
            It seems you have landed in the wrong place!
          </h2>
          <Link
            to="/vision"
            className="rounded-full bg-mainColour px-14 py-3 text-2xl font-medium leading-none text-white"
            aria-label="Go Back"
          >
            Go Back
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default connect(({ token }: RootStateTypeExtra) => ({
  token,
}))(PageNotFound)

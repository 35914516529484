import React from 'react'

import { SvgProps } from './types'

function CheckmarkCircleSvg({
  width,
  height,
  className,
  size,
  styles,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 18 19"
      stroke="none"
    >
      <path
        d="M9 18.5C4.0293 18.5 0 14.4707 0 9.5C0 4.5293 4.0293 0.5 9 0.5C13.9707 0.5 18 4.5293 18 9.5C18 14.4707 13.9707 18.5 9 18.5ZM8.1027 13.1L14.4657 6.7361L13.1931 5.4635L8.1027 10.5548L5.5566 8.0087L4.284 9.2813L8.1027 13.1Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckmarkCircleSvg

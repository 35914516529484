import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { SessionMap } from '@src/firebase-util/types'

import { setVision } from '@store/actionSlices/vision'
import { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import GalleryHandler from '@components/gallery-handler'

import { VisionData, selectFromResult, useGetVisionQuery } from '@api/vision'

import { SettingsSvg } from '@svg/react'

import VisionSkeleton from './vision-skeleton'

export interface VisionProps {
  projectName: string
  vision: VisionData
  session: SessionMap | undefined
}

function Vision({ projectName, vision, session }: VisionProps) {
  const dispatch = useDispatch()
  const [toggleControls, setToggleControls] = React.useState(true)
  const [isConnected, setIsConnected] = React.useState(false)

  const visionPayload = useGetVisionQuery({ projectName }, { selectFromResult })

  const handleGalleryHandlerClick = () => {
    setToggleControls((state) => !state)
  }

  const carouselData = React.useMemo(
    () =>
      vision.gallery.length > 0
        ? {
            slides: vision.gallery.map((res) => ({
              id: res.id,
              src: res.imageSource,
            })),
            labels: vision.gallery.map((res, index) => ({
              label: `label-${index}`,
              sublabel: `sublabel-${index}`,
            })),
          }
        : { slides: [], labels: [] },
    [vision]
  )

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)

      if (connected) {
        setToggleControls(false)
      }
    }
  }, [session])

  React.useEffect(() => {
    const { gallery, title } = visionPayload
    if (vision.gallery.length === 0 && gallery.length > 0) {
      dispatch(
        setVision({
          gallery,
          title,
        })
      )
    }
  }, [visionPayload])

  return (
    <Container>
      <DataHandler
        payload={{
          ...visionPayload,
          data: vision.gallery,
        }}
        skeletonFrame={<VisionSkeleton />}
      >
        <GalleryHandler
          galleryName="vision"
          galleryControl={session?.vision.galleryControl}
          slides={carouselData.slides}
          labels={carouselData.labels}
          dotType="label"
          onClick={handleGalleryHandlerClick}
          toggleControls={toggleControls}
          isConnected={isConnected}
          labelPosition="right"
        />
      </DataHandler>
      <FadeContainer show={!isConnected || toggleControls}>
        <Link
          to="settings"
          className="absolute bottom-5 left-5 z-20 opacity-[0.65]"
        >
          <SettingsSvg className="text-white drop-shadow-[3px_3px_4px_rgb(0_0_0_/_0.5)]" />
        </Link>
      </FadeContainer>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    vision,
    firestore: { session },
  }: RootStateTypeExtra) => ({
    projectName,
    vision,
    session,
  })
)(Vision)

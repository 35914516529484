import React from 'react'

function ShortlistSkeleton() {
  return (
    <div className="flex h-full w-screen bg-white p-3">
      <div className="flex h-full w-5/12 flex-col items-center rounded-2xl bg-neutral-grey-2 p-11 pb-4">
        <div className="mb-12 h-4 w-full rounded-lg bg-zinc-400/40">
          <div className="shimmer-container relative h-full rounded-none p-0 opacity-50 shadow-none"></div>
        </div>
        <div className="mb-8 h-60 w-full rounded-lg bg-zinc-400/40">
          <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
        </div>
        <div className="mb-8 h-60 w-full rounded-lg bg-zinc-400/40">
          <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex h-full flex-col items-center p-3">
          <div className="mb-10 h-60 w-60 rounded-lg bg-zinc-400/20">
            <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
          </div>
          <div className="h-4 w-4/6 rounded-lg bg-zinc-400/20">
            <div className="shimmer-container relative h-full rounded-none p-0 opacity-50 shadow-none"></div>
          </div>
          <div className="mt-8 w-5/12">
            <div className="h-4 w-44 rounded-lg bg-zinc-400/20">
              <div className="shimmer-container relative h-full rounded-none p-0 opacity-50 shadow-none"></div>
            </div>
            <div className="mt-4 h-32 rounded-lg bg-zinc-400/20">
              <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortlistSkeleton

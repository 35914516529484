import React from 'react'

import { SvgProps } from './types'

function DefaultLogoSvg({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48'}
      height={height || '48'}
      fill="currentColor"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 27 26"
      strokeWidth={stroke || 0.5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 26H26.3009V25.6542V0H25.9516C21.2984 0 16.9312 1.22314 13.1509 3.35175C9.37059 1.22314 5.00342 0 0.350222 0H0V25.6542V26ZM25.6016 19.7564C23.9878 13.0046 19.6742 7.27343 13.8472 3.75097C17.3477 1.85379 21.3488 0.751208 25.6016 0.69401V19.7564ZM0.70247 25.3081C0.82655 16.3036 5.80093 8.44121 13.1512 4.14968C20.5015 8.44121 25.4759 16.3036 25.5999 25.3081H0.70247ZM0.70078 0.69401C4.95272 0.751208 8.95477 1.85379 12.4544 3.75097C6.62727 7.27343 2.31462 13.0046 0.70078 19.7564V0.69401Z"
      />
    </svg>
  )
}

export default DefaultLogoSvg

/* eslint-disable react/prop-types */
import React, { RefObject, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { Range, getTrackBackground } from 'react-range'

interface ScrubBarProps {
  playerReference: RefObject<ReactPlayer>
  videoLengthInMilliseconds: number
  isPlaying: boolean
  isBuffing: boolean
  brandColour: string
}

function ScrubBar({
  playerReference,
  videoLengthInMilliseconds,
  isPlaying,
  isBuffing,
  brandColour,
}: ScrubBarProps) {
  const [sliderRangeValue, setSliderRangeValue] = React.useState<number[]>([0])

  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const setSliderRangeInterval = () => {
    if (intervalId) return

    setIntervalId(
      setInterval(() => {
        setSliderRangeValue((previousValue) => {
          const newValue = (previousValue[0] || 0) + 250
          return newValue > videoLengthInMilliseconds ? [0] : [newValue]
        })
      }, 250)
    )
  }

  const resetSliderRangeInterval = () => {
    if (intervalId) clearInterval(intervalId)
    setIntervalId(undefined)
  }

  const handleOnChange = (valueInMilliseconds: number[]) => {
    resetSliderRangeInterval()
    setSliderRangeValue(valueInMilliseconds)
  }

  const handleOnFinalChange = (valueInMilliseconds: number[]) => {
    playerReference.current?.seekTo((valueInMilliseconds[0] || 0) / 1000)
    if (isPlaying && !isBuffing) setSliderRangeInterval()
  }

  useEffect(() => {
    if (isPlaying && !isBuffing) {
      setSliderRangeInterval()
    } else {
      resetSliderRangeInterval()
    }
  }, [isPlaying, isBuffing])

  useEffect(
    () => () => {
      resetSliderRangeInterval()
    },
    []
  )

  return (
    <div className="group relative">
      <Range
        min={0}
        max={videoLengthInMilliseconds}
        step={0.1}
        values={sliderRangeValue}
        onChange={(value) => handleOnChange(value)}
        onFinalChange={(value) => handleOnFinalChange(value)}
        renderTrack={({ props, children }) => (
          <div
            role="slider"
            tabIndex={0}
            aria-valuemin={0}
            aria-valuemax={videoLengthInMilliseconds}
            aria-valuenow={sliderRangeValue[0]}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '30px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              className="h-2 w-full rounded"
              style={{
                background: getTrackBackground({
                  values: sliderRangeValue,
                  colors: [brandColour, '#71717a'],
                  min: 0,
                  max: videoLengthInMilliseconds,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            key={props.key}
            className="h-4 w-4 rounded-full opacity-0 group-hover:opacity-100"
            style={{
              backgroundColor: brandColour,
            }}
          ></div>
        )}
      />
    </div>
  )
}

export default ScrubBar

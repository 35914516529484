import React from 'react'

function GallerySkeleton() {
  return (
    <div className="relative flex h-screen w-full flex-col justify-between overflow-hidden bg-secondaryColour">
      <div className="m-8 flex flex-col gap-4">
        <div className="skeleton h-6 w-[260px]"></div>
        <div className="skeleton h-6 w-[220px]"></div>
        <div className="skeleton h-6 w-[260px]"></div>
        <div className="skeleton h-6 w-[220px]"></div>
      </div>
      <div className="m-8 flex gap-4 pb-8">
        <div className="skeleton h-[100px] w-[120px]"></div>
        <div className="skeleton h-[100px] w-[120px]"></div>
        <div className="skeleton h-[100px] w-[120px]"></div>
        <div className="skeleton h-[100px] w-[120px]"></div>
      </div>
    </div>
  )
}

export default GallerySkeleton

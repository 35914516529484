import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  BedConfigInterface,
  HouseAndLandConfigurationInterface,
  MenuItemInterface,
  PriceRange,
  RootStateTypeExtra,
} from '@store/types'

import {
  EnvisionVRConfigurationInterface,
  GallerySettingsInterface,
  LogoInterface,
  MarkerColourInterface,
  SnaploaderConfigurationInterface,
  StatusLabels,
  ThemeConfigInterface,
} from '@api/config'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

export interface ConfigInterface {
  logo: LogoInterface
  prices: PriceRange
  markerColour: MarkerColourInterface
  threeDViewerUrl: string
  bedConfig: Array<BedConfigInterface>
  houseAndLandConfig: HouseAndLandConfigurationInterface
  aspects: Array<string>
  unitTypes: Array<string>
  longhand: boolean
  showPrice: boolean
  showCarouselIndicator: boolean
  carouselIndicatorLocation: string
  hideSoldPrice: boolean
  showcaseRefreshTriggerKey: string
  hideFilter: boolean
  sidePanelTitle: string
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  unitPrefix: string
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  controls: {
    active: boolean
    duration: number
  }
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  theme: ThemeConfigInterface
  gallerySettings: GallerySettingsInterface
  navigationSettings: Array<MenuItemInterface>
  disableSoldUnit: boolean
  disableSoldLot: boolean
  type: string
  lightUpBuildingModelOnFilter: boolean
  statusLabels: StatusLabels
}

export interface ConfigInterfaceOptional {
  logo?: LogoInterface
  prices?: PriceRange
  markerColour?: MarkerColourInterface
  threeDViewerUrl?: string
  bedConfig?: Array<BedConfigInterface>
  houseAndLandConfig?: HouseAndLandConfigurationInterface
  aspects?: Array<string>
  unitTypes?: Array<string>
  longhand?: boolean
  showPrice?: boolean
  showCarouselIndicator?: boolean
  carouselIndicatorLocation?: string
  hideSoldPrice?: boolean
  showcaseRefreshTriggerKey?: string
  hideFilter?: boolean
  sidePanelTitle?: string
  hideUnitInfoName?: boolean
  hideInfoPanelAspect?: boolean
  unitPrefix?: string
  unitGalleryControlStyle?: string
  onlyShowUnitGalleries?: boolean
  controls?: {
    active: boolean
    duration: number
  }
  snaploaderConfiguration?: SnaploaderConfigurationInterface
  envisionVRConfiguration?: EnvisionVRConfigurationInterface
  theme?: ThemeConfigInterface
  lightUpBuildingModelOnFilter?: boolean
  statusLabels?: StatusLabels
}

const configDefault = {
  hideSoldPrice: false,
  longhand: false,
  showPrice: false,
  showCarouselIndicator: false,
  carouselIndicatorLocation: 'right',
  logo: {
    visible: false,
    src: '',
  },
  prices: {
    max: [],
    min: [],
  },
  markerColour: {
    available: '#FFFFFF',
    sold: '#D14417',
    area: '#FFFFFF',
    levels: '#FFFFFF',
  },
  threeDViewerUrl: '',
  bedConfig: [],
  houseAndLandConfig: {},
  aspects: [],
  unitTypes: [],
  showcaseRefreshTriggerKey: '',
  hideFilter: false,
  sidePanelTitle: 'Unit',
  hideUnitInfoName: false,
  hideInfoPanelAspect: false,
  unitPrefix: 'Unit',
  unitGalleryControlStyle: 'thumbnail',
  onlyShowUnitGalleries: false,
  controls: {
    active: false,
    duration: 0,
  },
  snaploaderConfiguration: {
    active: false,
    sceneSnapId: '',
    sceneModelId: '',
  },
  envisionVRConfiguration: {
    active: false,
    url: '',
    zoom: 0.5,
    rotateCameraBy: 0,
  },
  theme: {
    font: '',
    mainColour: '',
    secondaryColour: '',
    tertiaryColour: '',
    neutralColour: '',
  },
  gallerySettings: {
    slideShow: {
      type: 'slide',
      intervalInSeconds: 7,
    },
  },
  navigationSettings: [],
  disableSoldUnit: false,
  disableSoldLot: false,
  type: ProjectType.Apartment,
  lightUpBuildingModelOnFilter: false,
  statusLabels: {},
}

let initialState = configDefault as ConfigInterface

const projectConfig = localStorage.getItem('projectConfig')

if (projectConfig) {
  initialState = JSON.parse(projectConfig)
}

const projectConfigSlice = createSlice({
  name: 'projectConfig',
  initialState,
  reducers: {
    setProjectConfig: (
      state: ConfigInterface,
      action: PayloadAction<ConfigInterfaceOptional>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, ...action.payload })
      )
      return { ...state, ...action.payload }
    },
    setPrices: (state: ConfigInterface, action: PayloadAction<PriceRange>) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, prices: action.payload })
      )
      return { ...state, prices: action.payload }
    },
    setMarkerColour: (
      state: ConfigInterface,
      action: PayloadAction<MarkerColourInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, markerColour: action.payload })
      )
      return { ...state, markerColour: action.payload }
    },
    setThreeDViewerUrl: (
      state: ConfigInterface,
      action: PayloadAction<string>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, threeDViewerUrl: action.payload })
      )
      return { ...state, threeDViewerUrl: action.payload }
    },
    setBedConfig: (
      state: ConfigInterface,
      action: PayloadAction<Array<BedConfigInterface>>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, bedConfig: action.payload })
      )
      return { ...state, bedConfig: action.payload }
    },
    setHouseAndLandConfig: (
      state: ConfigInterface,
      action: PayloadAction<HouseAndLandConfigurationInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, houseAndLandConfig: action.payload })
      )
      return { ...state, houseAndLandConfig: action.payload }
    },
    setAspect: (
      state: ConfigInterface,
      action: PayloadAction<Array<string>>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, aspects: action.payload })
      )
      return { ...state, aspects: action.payload }
    },
    setNavigationSettings: (
      state: ConfigInterface,
      action: PayloadAction<Array<MenuItemInterface>>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, navigationSettings: action.payload })
      )
      return { ...state, navigationSettings: action.payload }
    },
    setUnitTypes: (
      state: ConfigInterface,
      action: PayloadAction<Array<string>>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, unitTypes: action.payload })
      )
      return { ...state, unitTypes: action.payload }
    },
    setShowcaseRefreshTriggerKey: (
      state: ConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        showcaseRefreshTriggerKey: action.payload,
      }
      localStorage.setItem('projectConfig', JSON.stringify(newState))
      return newState
    },
    setSnaploaderConfiguration: (
      state: ConfigInterface,
      action: PayloadAction<SnaploaderConfigurationInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, snaploaderConfiguration: action.payload })
      )
      return { ...state, snaploaderConfiguration: action.payload }
    },
    setEnvisionVRConfiguration: (
      state: ConfigInterface,
      action: PayloadAction<EnvisionVRConfigurationInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, envisionVRConfiguration: action.payload })
      )
      return { ...state, envisionVRConfiguration: action.payload }
    },
    setThemeConfiguration: (
      state: ConfigInterface,
      action: PayloadAction<ThemeConfigInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, theme: action.payload })
      )
      return { ...state, theme: action.payload }
    },
    setGallerySettings: (
      state: ConfigInterface,
      action: PayloadAction<GallerySettingsInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, gallerySettings: action.payload })
      )
      return { ...state, gallerySettings: action.payload }
    },
    setDisableUnitSoldInfo: (
      state: ConfigInterface,
      action: PayloadAction<boolean>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, disableSoldUnit: action.payload })
      )
      return { ...state, disableSoldUnit: action.payload }
    },
    setProjectType: (
      state: ConfigInterface,
      action: PayloadAction<ProjectType>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, type: action.payload })
      )
      return { ...state, type: action.payload }
    },
    setLightUpBuildingModelOnFilter: (
      state: ConfigInterface,
      action: PayloadAction<boolean>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({
          ...state,
          lightUpBuildingModelOnFilter: action.payload,
        })
      )
      return { ...state, lightUpBuildingModelOnFilter: action.payload }
    },
    setStatusLabels: (
      state: ConfigInterface,
      action: PayloadAction<StatusLabels>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({
          ...state,
          stat: action.payload,
        })
      )
      return { ...state, statusLabels: action.payload }
    },
    removeProjectConfig: () => {
      localStorage.removeItem('projectConfig')
      return configDefault
    },
  },
})

export const {
  setProjectConfig,
  removeProjectConfig,
  setThreeDViewerUrl,
  setMarkerColour,
  setPrices,
  setShowcaseRefreshTriggerKey,
  setSnaploaderConfiguration,
  setBedConfig,
  setHouseAndLandConfig,
  setAspect,
  setNavigationSettings,
  setUnitTypes,
  setEnvisionVRConfiguration,
  setThemeConfiguration,
  setGallerySettings,
  setDisableUnitSoldInfo,
  setProjectType,
  setLightUpBuildingModelOnFilter,
  setStatusLabels,
} = projectConfigSlice.actions

export const selectProjectConfig = (state: RootStateTypeExtra) =>
  state.projectConfig

export default projectConfigSlice.reducer

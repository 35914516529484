import React from 'react'

import { SvgProps } from './types'

function LinkSvg({
  width,
  height,
  className,
  size,
  styles,
  strokeColor,
  stroke,
}: SvgProps) {
  const sizeKey = size || 's'
  const scale = {
    xl: 3.0,
    lg: 1.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '22'}
      height={height || '22'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 5H4.25C3.65326 5 3.08097 5.23705 2.65901 5.65901C2.23705 6.08097 2 6.65326 2 7.25V17.75C2 18.3467 2.23705 18.919 2.65901 19.341C3.08097 19.7629 3.65326 20 4.25 20H14.75C15.3467 20 15.919 19.7629 16.341 19.341C16.7629 18.919 17 18.3467 17 17.75V9.5M6.5 15.5L20 2M20 2H14.75M20 2V7.25"
        stroke={strokeColor || 'currentColor'}
        strokeWidth={stroke || '2.5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkSvg

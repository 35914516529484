import { ANY } from './constants/common'
import { MinMaxInterface } from './types/common'

const validateEmailFormat = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

const getQueryStringParams = (query: string) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}

const formatAreaNumber = (num: string | number) => {
  const convertedNum = Number(num)
  return Number.isNaN(convertedNum) ? num : Math.round(convertedNum * 100) / 100
}

const isValueInRange = (range: MinMaxInterface, value: number): boolean => {
  const { min, max } = range

  if (min === ANY && max === ANY) {
    return true
  }
  if (min === ANY) {
    return value <= Number(max)
  }
  if (max === ANY) {
    return value >= Number(min)
  }

  return value >= Number(min) && value <= Number(max)
}

export {
  validateEmailFormat,
  getQueryStringParams,
  formatAreaNumber,
  isValueInRange,
}

import React from 'react'

import { SvgProps } from './types'

function StopCircleSvg({
  width,
  height,
  className,
  stroke,
  strokeColor,
  fill,
}: SvgProps) {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill || 'currentColor'}
      viewBox="0 0 24 24"
      stroke={strokeColor || 'currentColor'}
      strokeWidth={stroke || '1.5'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
      />
    </svg>
  )
}

export default StopCircleSvg

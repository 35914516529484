import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setVideoGallery } from '@store/actionSlices/videoGallery'
import { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { GalleryProvider } from '@components/gallery-handler'

import {
  VideoGalleryData,
  selectFromResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import CarouselContent from './carousel-content'
import VideoSkeleton from './video-skeleton'

export interface VideoGalleryProps {
  projectName: string
  videoGallery: Array<VideoGalleryData>
  session: SessionMap | undefined
}

function VideoGallery({
  projectName,
  videoGallery,
  session,
}: VideoGalleryProps) {
  const dispatch = useDispatch()

  const [ctrlsHidden, setCtrlsVisibility] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { videoGalleryData } = videoGalleryPayload

    if (videoGallery.length === 0 && videoGalleryData.length > 0) {
      dispatch(setVideoGallery(videoGalleryData))
    }
  }, [videoGalleryPayload, videoGallery])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  return (
    <Container fullScreenMode={ctrlsHidden}>
      <DataHandler
        payload={{
          ...videoGalleryPayload,
          data: videoGallery,
          apiData: videoGalleryPayload.videoGalleryData,
        }}
        skeletonFrame={<VideoSkeleton />}
      >
        <GalleryProvider
          galleryName="videoGallery"
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className={`h-full ipad-mini:w-1100px ${
            ctrlsHidden ? 'relative z-50' : ''
          }`}
          originalSlides={videoGallery}
          galleryControl={session?.videoGallery?.galleryControl}
        >
          {(slideContent) => (
            <CarouselContent
              slideContent={slideContent}
              ctrlsHidden={isConnected ? true : ctrlsHidden}
              getCtrlVisibility={setCtrlsVisibility}
            />
          )}
        </GalleryProvider>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    videoGallery,
    firestore: { session },
  }: RootStateTypeExtra) => ({
    projectName,
    videoGallery,
    session,
  })
)(VideoGallery)

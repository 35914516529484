import React from 'react'

function StageSkeleton() {
  return (
    <div className="h-full w-full bg-mainColour">
      <div className="shimmer-container relative h-full"></div>
    </div>
  )
}

export default StageSkeleton

import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setUnitGallery } from '@store/actionSlices/unitGallery'
import {
  GalleryControlInterface,
  RootStateTypeExtra,
  SessionMap,
  UnitGallery,
  UnitGalleryData,
} from '@store/types'

import DataHandler from '@components/data-handler'
import { GalleryProvider } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton'

import { GallerySettingsInterface } from '@api/config'
import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

import { ArrowSvg } from '@svg/react'

import { FileType } from '@adUtilities/constants/common'
import { Unit, ViewLineImage } from '@adUtilities/types/apartment'

import TopControlBar from './top-control-bar'
import UnitCarouselContent from './unit-carousel-content'
import UnitInfoCard from './unit-info-card'

export interface UnitViewProps {
  unit: Unit
  projectName: string
  projectId: string
  session: SessionMap | undefined
  panoramic: Array<PannellumDataInterface>
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  unitGallery: Array<UnitGalleryData>
  fullScreenToggle: boolean
  gallerySettings: GallerySettingsInterface
  setFullScreenToggle: (arg: boolean) => void
}

const NO_IMAGE_OBJECT: UnitGallery = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

function UnitView({
  unit,
  projectName,
  projectId,
  session,
  panoramic,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  unitGallery,
  fullScreenToggle,
  setFullScreenToggle,
  gallerySettings,
}: UnitViewProps) {
  const dispatch = useDispatch()

  const [unitInfoToggle, setUnitInfoToggle] = React.useState(false)
  const [hasPanoramicData, setPanoramicDataState] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [gallery, setGallery] = React.useState<Array<UnitGallery>>([])
  const [skipUnitGalleryApi, setSkipUnitGalleryApi] = React.useState(true)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState(false)
  const [localGalleryControl, setLocalGalleryControl] =
    React.useState<GalleryControlInterface>({
      activeTabIndex: 0,
      activeItemID: '',
      transitionTime: 7000,
      isPlaying: false,
      transitionType: 'slide',
    })
  const [showExternalLinks, setShowExternalLinks] = React.useState(false)

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult, skip: skipUnitGalleryApi }
  )

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName, propertyId: unit?.name || '' },
    {
      skip: !unit?.name,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  React.useEffect(() => {
    const { slideShow } = gallerySettings

    setLocalGalleryControl((prevState) => ({
      ...prevState,
      transitionTime: slideShow.intervalInSeconds * 1000,
      transitionType: slideShow.type,
    }))
  }, [gallerySettings])

  const unitGalleryExistsInTheStore = (unitId: string): boolean =>
    unitGallery.find(
      (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
    ) !== undefined

  React.useEffect(() => {
    const foundData = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === 'unit' &&
        item.panoramaGroup?.toString() === unit.name?.toString()
    )
    setPanoramicDataState(foundData !== undefined)
  }, [panoramic, unit])

  React.useEffect(() => {
    if (!unitGalleryExistsInTheStore(unit.id) && skipUnitGalleryApi) {
      setSkipUnitGalleryApi(false)
    }
  }, [unitGallery])

  React.useEffect(() => {
    const { unitGalleryData, isLoaded, isError } = unitGalleryPayload
    const unitId = unit.id
    if (
      isLoaded &&
      unitGalleryData.length > 0 &&
      !unitGalleryExistsInTheStore(unitId) &&
      !isError
    ) {
      dispatch(setUnitGallery({ unit: unitId, items: unitGalleryData }))
    }
  }, [unitGalleryPayload])

  const buildUnitGallery = () => {
    const galleryData: Array<UnitGallery> = []
    const {
      id: unitId,
      metas: { floorImage, floorLabel, floorPdf, viewLineImages },
    } = unit

    if (!onlyShowUnitGalleries && viewLineImages && viewLineImages.length > 0) {
      viewLineImages.forEach((item: ViewLineImage) => {
        galleryData.push({
          ...item,
          id: item.id,
          src: `/${projectId}/views/${item.src}`,
          type: 'image',
          noSpliceUrl: true,
        })
      })
    }

    const foundUnitGallerys = unitGallery.filter(
      (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
    )
    if (foundUnitGallerys.length > 0) {
      const [foundUnitGallery] = foundUnitGallerys
      foundUnitGallery.items.forEach((galleryItem: UnitGallery) => {
        const gradiant = galleryItem.type === 'video' ? 0.5 : 0
        galleryData.push({ ...galleryItem, gradiant, noSpliceUrl: false })
      })
    }

    if (!onlyShowUnitGalleries && floorImage) {
      galleryData.unshift({
        id: floorPdf,
        type: 'image',
        src: `/${projectId}/floorplans/preview/${floorImage}`,
        label: floorLabel || floorImage,
        noSpliceUrl: true,
      })
    }

    if (!galleryData.length) {
      galleryData.push(NO_IMAGE_OBJECT)
    }

    setGallery(galleryData)
  }

  const setSlideShowToggle = () => {
    setLocalGalleryControl((state) => ({
      ...state,
      isPlaying: !state.isPlaying,
    }))
  }

  React.useEffect(() => {
    buildUnitGallery()
  }, [unit, unitGallery])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { unitInfoPopup, externalLinkPopup },
        unitGallery: { galleryControl },
      } = session
      setIsConnected(connected)
      setUnitInfoToggle(unitInfoPopup)
      setShowExternalLinks(externalLinkPopup)
      setLocalGalleryControl(galleryControl)
    }
  }, [session])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(gallery.length > 1)
  }, [gallery])

  return (
    <DataHandler
      payload={{
        ...unitGalleryPayload,
        data: gallery,
        apiData: unitGalleryPayload.unitGalleryData,
      }}
      skeletonFrame={<Skeleton containerClass="!w-full absolute" />}
    >
      <div className="relative flex h-full w-full">
        <IdleTimeHandler disable={isConnected}>
          {!isConnected && (
            <div className="absolute top-0 z-5 w-full items-start p-4">
              <TopControlBar
                hasPanoramicData={hasPanoramicData}
                fullScreenToggle={fullScreenToggle}
                setFullScreenToggle={setFullScreenToggle}
                unitInfoToggle={unitInfoToggle}
                slideShowToggle={localGalleryControl.isPlaying}
                shouldShowSlideshowBtn={shouldShowSlideshowBtn}
                setSlideShowToggle={(arg: boolean) => {
                  setSlideShowToggle()
                  setFullScreenToggle(arg)
                }}
                externalLinkToggle={
                  externalLinksPayload?.externalLinks?.length > 0
                }
                setShowExternalLinks={setShowExternalLinks}
                showExternalLinks={showExternalLinks}
                setUnitInfoToggle={setUnitInfoToggle}
                isVideoPlaying={isVideoPlaying}
              />
            </div>
          )}
          <div
            className={`pointer-events-none absolute bottom-0 z-20 h-92 w-full bg-shadow-horizontal opacity-75 transition duration-300 ease-in ${
              unitInfoToggle &&
              !localGalleryControl.isPlaying &&
              !isVideoPlaying
                ? 'translate-y-0'
                : 'translate-y-full'
            }`}
          >
            <div
              className={`flex h-full transform items-stretch duration-200 ease-in ${
                fullScreenToggle ? 'pb-2' : 'pb-12'
              } pl-5`}
            >
              <div className="self-end">
                <UnitInfoCard unit={unit} />
              </div>
            </div>
          </div>
        </IdleTimeHandler>
        <GalleryProvider
          galleryName="building"
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          dragEnabled={false}
          className="building h-full w-full"
          originalSlides={gallery}
          galleryControl={localGalleryControl}
          isConnected={isConnected}
        >
          {(slideContent) => (
            <UnitCarouselContent
              gallery={slideContent}
              session={session}
              isConnected={isConnected}
              unitGalleryControlStyle={unitGalleryControlStyle}
              galleryControl={localGalleryControl}
              getVideoPlayerState={(arg: boolean) => {
                setIsVideoPlaying(arg)
                setFullScreenToggle(arg)
              }}
            />
          )}
        </GalleryProvider>
      </div>

      {showExternalLinks && (
        <div
          className={`fixed left-0 z-30 w-screen bg-secondaryColour ${
            isConnected || fullScreenToggle
              ? 'top-0 h-screen'
              : 'top-9 h-page-container overflow-hidden rounded-t-3xl'
          }`}
        >
          {!isConnected && (
            <button
              type="button"
              className="absolute left-5 top-5 z-3 rounded bg-white p-1 drop-shadow-40"
              onClick={() => setShowExternalLinks(false)}
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </button>
          )}

          <div className="absolute left-1/2 top-1/2 z-1 -translate-x-1/2 -translate-y-1/2">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
          </div>

          <iframe
            key={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            className="relative z-2 h-full w-full overflow-hidden border-0"
            title={externalLinksPayload?.externalLinks?.[0]?.label}
            src={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            height="100%"
            width="100%"
          />
        </div>
      )}
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName, projectId },
    firestore: { session },
    panoramic,
    projectConfig: {
      unitGalleryControlStyle,
      onlyShowUnitGalleries,
      gallerySettings,
    },
    unitGallery,
  }: RootStateTypeExtra) => ({
    projectName,
    projectId,
    session,
    panoramic,
    unitGalleryControlStyle,
    onlyShowUnitGalleries,
    unitGallery,
    gallerySettings,
  })
)(UnitView)

import React from 'react'

function InteractiveMapSkeleton() {
  return (
    <div className="relative flex h-screen w-full flex-col items-start justify-end overflow-hidden bg-secondaryColour">
      <div className="skeleton mb-[60px] ml-8 h-[160px] w-[280px]"></div>
    </div>
  )
}

export default InteractiveMapSkeleton

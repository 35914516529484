import { FileType } from '@utilities/adgroup-utilities/constants/common'
import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'

export enum LotStatusType {
  Sold = 'sold',
  Available = 'available',
}

export enum HouseAndLandActiveKeyType {
  ActivePrecinctId = 'activePrecinctId',
  ActiveStageId = 'activeStageId',
  ActiveLotId = 'activeLotId',
  ActiveLotName = 'activeLotName',
  ActivePackageId = 'activePackageId',
  ActivePackageOption = 'activePackageOption',
}

export interface ConfigurationInterface {
  bed: number
  study: number
  bath: number
  powderRoom: number
  car: number
}

export interface FileInterface {
  id: string
  name: string
  title: string
  url: string
  order: number
}

export interface FloorplanOptionInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
}

export interface FacadeColorInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  thumbnail: FileInterface
}

export interface FacadeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  thumbnail: FileInterface
  facadeColors: Array<FacadeColorInterface>
}

export interface InternalColorSchemeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
}

export interface InternalThemeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  internalColorSchemes: Array<InternalColorSchemeInterface>
}

export interface PackageOption {
  id: string
  packageId: string
  floorplanOptions: Array<FloorplanOptionInterface>
  facades: Array<FacadeInterface>
  internalThemes: Array<InternalThemeInterface>
}

export interface GalleryInterface {
  id: string
  name: string
  title: string
  url: string
  type: FileType
  thumbnail?: string
  order: number
  position: number
}

export interface PackageInterface {
  id: string
  name: string
  storey: number
  builder: string
  configuration: ConfigurationInterface
  lots: Array<string>
}

export interface PackageOptionsInterface {
  id: string
  name: string
  storey: number
  order: number
  houseSize: number
  housePrice: number
  builder: string
  title: string
  configuration: ConfigurationInterface
  galleries?: Array<GalleryInterface>
  files?: Array<FileInterface>
  floorplanOptions?: Array<FloorplanOptionInterface>
  facades?: Array<FacadeInterface>
  internalThemes?: Array<InternalThemeInterface>
}

export interface LotAssetInterface {
  id: string
  name: string
  order: number
  projectId: string
  showcaseRoomId: string
  title: string
  type: FileType
  url: string
  uuid: string
}

export interface LotInterface {
  id: string
  name: string
  area: string
  frontage: number
  aspect: string
  price: number
  status: LotStatusType
  assets?: {
    floorImage?: LotAssetInterface
    viewImage?: LotAssetInterface[]
    viewLinesImage?: LotAssetInterface[]
  }
}

export interface StageInterface {
  id: string
  label: string
  lots: Array<LotInterface>
}

export interface StageCollectionInterface {
  [key: string]: StageInterface
}

export interface MatrixDataInterface {
  packages: Array<PackageInterface>
  stages: StageCollectionInterface
}

export interface MatrixDataCollectionInterface {
  [key: string]: MatrixDataInterface
}

export interface PrecinctListItemInterface {
  id: string
  label: string
}

export type ActivePackageConfigurationType = {
  [key in HouseAndLandPackageOptionType]: string
}

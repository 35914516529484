import React from 'react'
import { useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { CSSTransitionProps } from 'react-transition-group/CSSTransition'

import useCurrentPage from '@src/utilities/location-util'

interface WithPageTransitionProps {
  children: React.ReactNode
}

function WithPageTransition({ children }: WithPageTransitionProps) {
  const location = useLocation()
  const [currentPage, previousPage] = useCurrentPage()

  const transitionProps = React.useMemo(() => {
    const props: CSSTransitionProps = {
      classNames: '',
      timeout: 300,
    }
    const slidingPages = ['location', 'interactive-map']

    if (slidingPages.includes(currentPage)) {
      props.classNames = 'transition-slide'
      return props
    }
    if (slidingPages.includes(previousPage)) {
      props.classNames = 'transition-fade-ease-slide'
      return props
    }
    props.classNames = 'transition-fade-ease'
    return props
  }, [location])

  return (
    <TransitionGroup
      childFactory={(child) =>
        React.cloneElement(child, { ...transitionProps })
      }
    >
      <CSSTransition
        key={location.pathname}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default WithPageTransition

import React from 'react'

function TeamsSkeleton() {
  return (
    <div className="relative flex h-screen w-full overflow-hidden bg-secondaryColour">
      <div className="flex w-[40%] flex-col justify-center bg-tertiaryColour">
        <div className="mx-[100px] flex flex-col gap-4">
          <div className="skeleton h-8 w-[60%]"></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="skeleton h-4 w-[100%]"></div>
          <div className="skeleton h-4 w-[94%]"></div>
          <div className="skeleton h-4 w-[100%]"></div>
          <div className="skeleton h-4 w-[88%]"></div>
          <br />
          <div className="skeleton h-4 w-[94%]"></div>
          <div className="skeleton h-4 w-[100%]"></div>
          <div className="skeleton h-4 w-[88%]"></div>
        </div>
        <div className="mx-[100px] mt-[60px] flex gap-4">
          <div className="skeleton w-[160px]"></div>
        </div>
      </div>
    </div>
  )
}

export default TeamsSkeleton

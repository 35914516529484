import React from 'react'

import { SvgProps } from './types'

function CarSvg({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48'}
      height={height || '48'}
      fill="none"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 18 17"
      strokeWidth={stroke || 1.4}
    >
      <path d="M6.41683 7.08331H12.0835M5.7085 9.91665H6.41683M12.0835 9.91665H12.7918" />
      <path d="M2.875 12.75V8.08206C2.87515 7.89039 2.91418 7.70075 2.98975 7.52461L4.63238 3.69252C4.74148 3.43761 4.923 3.22032 5.15443 3.06759C5.38585 2.91486 5.65701 2.8334 5.93429 2.83331H12.5657C12.8429 2.83334 13.114 2.9147 13.3454 3.0673C13.5768 3.2199 13.7584 3.43703 13.8676 3.69181L15.511 7.5239C15.5865 7.70053 15.6253 7.89067 15.625 8.08277V12.75M2.875 12.75V14.45C2.875 14.5627 2.91978 14.6708 2.99948 14.7505C3.07918 14.8302 3.18728 14.875 3.3 14.875H5.28333C5.39605 14.875 5.50415 14.8302 5.58385 14.7505C5.66356 14.6708 5.70833 14.5627 5.70833 14.45V12.75M2.875 12.75H5.70833M15.625 12.75V14.45C15.625 14.5627 15.5802 14.6708 15.5005 14.7505C15.4208 14.8302 15.3127 14.875 15.2 14.875H13.2167C13.1609 14.875 13.1056 14.864 13.054 14.8426C13.0025 14.8213 12.9556 14.79 12.9161 14.7505C12.8767 14.711 12.8454 14.6642 12.824 14.6126C12.8027 14.5611 12.7917 14.5058 12.7917 14.45V12.75M15.625 12.75H12.7917M5.70833 12.75H12.7917" />
    </svg>
  )
}

export default CarSvg

export const ANY = 'Any'

export enum Status {
  Available = 'available',
}

export enum FileType {
  Image = 'image',
  Video = 'video',
}

export enum ProjectType {
  HouseAndLand = 'house-lands',
  Apartment = 'apartments',
}

import React, { MouseEventHandler } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { setByFlag } from '@store/actionSlices/houseAndLand'

import { HouseAndLandActiveKeyType } from '@api/types/house-and-land-type'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-hnl-util'

import Layer from './layer'

interface LayerHnlProps {
  label: string
  actionRoute: string
}

function LayerHnl({ label, actionRoute }: LayerHnlProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [parseMapActionRoute] = useParseMapLayerActionRoute()

  const {
    pathWithParams: path,
    params,
    isValid,
  } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePrecinctId,
          value: params.precinct || '',
        })
      )
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: params.stage || '',
        })
      )
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
      )
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveLotName,
          value: params.lot || '',
        })
      )
      history.push(path)
    },
    [path, params]
  )

  return (
    <Layer label={label} path={path} isValid={isValid} onClick={handleClick} />
  )
}

export default LayerHnl

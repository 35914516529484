import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setTeams } from '@store/actionSlices/teams'
import { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { GalleryProvider } from '@components/gallery-handler'

import { TeamsInfo, selectFromResult, useGetTeamsQuery } from '@api/teams'

import CarouselContent from './carousel-content'
import TeamsSkeleton from './teams-skeleton'

export interface TeamsProps {
  session: SessionMap | undefined
  projectName: string
  teams: Array<TeamsInfo>
}

function Teams({ session, projectName, teams }: TeamsProps) {
  const dispatch = useDispatch()

  const teamsPayload = useGetTeamsQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { teamsInfo } = teamsPayload
    if (teams.length === 0 && teamsInfo.length > 0) {
      dispatch(setTeams(teamsInfo))
    }
  }, [teamsPayload, teams])

  const slideData = React.useMemo(() => {
    const logoData = teams.filter((team) => team.type === 'logo')
    const backgroundData = teams.filter((team) => team.type === 'background')

    return logoData.map((data, index) => ({
      ...data,
      backgroundSrc: backgroundData[index]?.imageSource ?? '',
    }))
  }, [teams])

  return (
    <Container>
      <DataHandler
        payload={{
          ...teamsPayload,
          data: teams,
        }}
        skeletonFrame={<TeamsSkeleton />}
      >
        <GalleryProvider
          galleryName="teams"
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className="h-full bg-tertiaryColour ipad-mini:w-1100px"
          originalSlides={slideData}
          galleryControl={session?.team?.galleryControl}
        >
          {(slideContent) => <CarouselContent slideContent={slideContent} />}
        </GalleryProvider>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
    teams,
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    teams,
  })
)(Teams)

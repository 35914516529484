import React from 'react'

import { SvgProps } from './types'

function FilterSvg({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48'}
      height={height || '48'}
      fill="currentColor"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 18 16"
      strokeWidth={stroke || 0.1}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7 6.58791C12.3784 6.58779 13.0387 6.3587 13.5827 5.93469C14.1267 5.51067 14.5254 4.91438 14.7195 4.23442H17.1C17.3387 4.23442 17.5676 4.13523 17.7364 3.95869C17.9052 3.78214 18 3.54269 18 3.29302C18 3.04335 17.9052 2.8039 17.7364 2.62735C17.5676 2.45081 17.3387 2.35162 17.1 2.35162H14.7195C14.5251 1.67201 14.1262 1.07613 13.5823 0.652472C13.0383 0.22882 12.3782 0 11.7 0C11.0218 0 10.3617 0.22882 9.81772 0.652472C9.27375 1.07613 8.87493 1.67201 8.6805 2.35162H0.9C0.661305 2.35162 0.432387 2.45081 0.263604 2.62735C0.0948211 2.8039 0 3.04335 0 3.29302C0 3.54269 0.0948211 3.78214 0.263604 3.95869C0.432387 4.13523 0.661305 4.23442 0.9 4.23442H8.6805C8.87461 4.91438 9.2733 5.51067 9.8173 5.93469C10.3613 6.3587 11.0216 6.58779 11.7 6.58791ZM0.9 11.7656C0.661305 11.7656 0.432387 11.8648 0.263604 12.0413C0.0948211 12.2179 0 12.4573 0 12.707C0 12.9567 0.0948211 13.1961 0.263604 13.3726C0.432387 13.5492 0.661305 13.6484 0.9 13.6484H2.8305C3.02493 14.328 3.42375 14.9239 3.96772 15.3475C4.5117 15.7712 5.1718 16 5.85 16C6.5282 16 7.1883 15.7712 7.73228 15.3475C8.27625 14.9239 8.67507 14.328 8.8695 13.6484H17.1C17.3387 13.6484 17.5676 13.5492 17.7364 13.3726C17.9052 13.1961 18 12.9567 18 12.707C18 12.4573 17.9052 12.2179 17.7364 12.0413C17.5676 11.8648 17.3387 11.7656 17.1 11.7656H8.8695C8.67507 11.086 8.27625 10.4901 7.73228 10.0664C7.1883 9.64278 6.5282 9.41396 5.85 9.41396C5.1718 9.41396 4.5117 9.64278 3.96772 10.0664C3.42375 10.4901 3.02493 11.086 2.8305 11.7656H0.9Z"
      />
    </svg>
  )
}

export default FilterSvg

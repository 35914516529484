import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { Unit } from '@adUtilities/types/apartment'

export interface Level {
  level: string
  data: Array<Unit>
  height: number
}

interface BlockInterface {
  label: string
  order: number
  levels: {
    [key: string]: {
      order: number
      label: string
      units: {
        [key: string]: Unit
      }
    }
  }
}

export interface BlockCollectionInterface {
  [key: string]: BlockInterface
}

interface BlocksPayloadInterface {
  building: BlockCollectionInterface
}

type BuildingType = {
  projectName: string
}

type BlockRequest = {
  projectName: string
  block: string
}

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBuilding: builder.query<ApiResponse<Array<Level>>, BuildingType>({
      query: (params: BuildingType) => ({
        url: `/v1/${params.projectName}/building`,
        method: 'get',
      }),
    }),
    getBlocks: builder.query<ApiResponse<BlocksPayloadInterface>, BlockRequest>(
      {
        query: (params: BlockRequest) => ({
          url: `/v2/building/${params.projectName}`,
          method: 'get',
          params: {
            block: params.block,
          },
        }),
      }
    ),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  building: Level[]
  blockOrders: string[]
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  building:
    data?.data?.lists?.toSorted(
      (itemA: Level, itemB: Level) =>
        (itemA?.height || 0) - (itemB?.height || 0)
    ) || [],
  blockOrders: data?.data?.blockOrders || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const selectBlocksFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  blocks: BlockCollectionInterface
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  blocks: data?.data?.building || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetBuildingQuery, useGetBlocksQuery } = buildingApi

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { setByFlag } from '@store/actionSlices/houseAndLand'
import { setPrecincts } from '@store/actionSlices/interactivePlan'
import { setFilter } from '@store/actionSlices/lotFilter'
import {
  InteractivePlanType,
  LotFilterInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import {
  FULFILLED_STATUS,
  PENDING_STATUS,
  UNINITIALIZED_STATUS,
} from '@components/data-handler/data-handler'
import useGetHouseAndLandFilterData from '@components/filter-popup//use-get-house-and-filter-data'
import FilterPopup from '@components/filter-popup/filter-popup-house-and-land'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import { StatusLabels } from '@api/config'
import {
  selectMatrixDataFromResult,
  useGetMatrixDataByPrecinctQuery,
} from '@api/houseAndLand'
import {
  MapContent,
  MappingBlockCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  HouseAndLandActiveKeyType,
  MatrixDataCollectionInterface,
  PrecinctListItemInterface,
  StageCollectionInterface,
} from '@api/types/house-and-land-type'

import styleUtil from '@utilities/style-util'

import { ArrowSvg, FilterSvg, MapPinSvg } from '@svg/react'

import filterPackageSummary from '@adUtilities/filter-package-summary'

import PrecinctSkeleton from './precinct-skeleton'

export interface PrecinctProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  precinct: MappingBlockCollection
  precinctList: PrecinctListItemInterface[]
  matrixData: MatrixDataCollectionInterface
  filterStages: StageCollectionInterface
  lotFilter: LotFilterInterface
  storeActivePrecinct: string
  levelMarkerColour: string
  statusLabels: StatusLabels
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

function Precinct({
  session,
  projectIdentity,
  precinct: storePrecincts,
  precinctList,
  matrixData,
  storeActivePrecinct,
  lotFilter,
  filterStages,
  levelMarkerColour,
  statusLabels,
}: PrecinctProps) {
  const themeData = styleUtil()
  const history = useHistory()
  const dispatch = useDispatch()

  const precinctKeys = React.useMemo(
    () => Object.keys(storePrecincts),
    [storePrecincts]
  )

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [activePrecinct, setActivePrecinct] =
    React.useState<string>(storeActivePrecinct)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [rendering, setRendering] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isCanvasImageLoaded, setImageLoaded] = React.useState(false)
  const [activeFacade, setFacade] = React.useState(0)

  const prevActivePrecinctRef = React.useRef(storeActivePrecinct)
  const [hasLoaded, setHasLoaded] = React.useState(false)

  const [isConnected, setIsConnected] = React.useState(false)

  useGetHouseAndLandFilterData({
    projectName: projectIdentity.projectName,
    precinctId: activePrecinct,
  })

  const matrixPayload = useGetMatrixDataByPrecinctQuery(
    {
      projectName: projectIdentity.projectName,
      precinctIdOrLabel: storeActivePrecinct,
    },
    {
      selectFromResult: selectMatrixDataFromResult,
      skip: !storeActivePrecinct,
    }
  )

  const interactivePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectName,
      type: InteractivePlanType.Precinct,
      slug: storeActivePrecinct,
    },
    {
      selectFromResult: selectFromResultInteractive,
      skip: Object.keys(matrixPayload.matrixData?.stages || {}).length === 0,
    }
  )
  React.useEffect(() => {
    const { maps } = interactivePayload
    if (maps.precinct && Object.keys(maps.precinct)?.length > 0) {
      dispatch(setPrecincts(maps.precinct))
    }
  }, [interactivePayload])

  const [facadeControl, setFacadeControl] = React.useState('')

  const getAvailableLotCount = (poly: Polygon) => {
    const splicedGroupId = poly.groupId.split('-')
    const foundStage = filterStages[splicedGroupId[1] || poly.groupId]
    if (foundStage) {
      return foundStage.lots?.filter(
        (res) => res.status === statusLabels?.available
      ).length
    }
    return 0
  }

  const getStageStatus = React.useCallback(
    (poly: Polygon) => {
      const { apply } = lotFilter
      const splicedGroupId = poly.groupId.split('-')

      if (!apply) {
        return false
      }
      const foundStage = filterStages[splicedGroupId[1] || poly.groupId]

      if (!foundStage) {
        return false
      }

      return foundStage.lots?.length > 0
    },
    [lotFilter, filterStages]
  )

  const getStageLotCount = React.useCallback(
    (poly: Polygon) => {
      const splicedGroupId = poly.groupId.split('-')
      const foundStage = filterStages[splicedGroupId[1] || poly.groupId]

      if (!foundStage) return 0

      return foundStage.lots?.length
    },
    [lotFilter, filterStages]
  )

  const findRelevantStage = React.useCallback(
    (stageName: string) =>
      matrixData?.[storeActivePrecinct]?.stages?.[stageName]?.lots,
    [matrixData, storeActivePrecinct]
  )

  const checkGroupId = React.useCallback((groupId: string) => {
    const splitGroupString = groupId.split('-')
    if (splitGroupString.length > 1) {
      const [activePrecinct, activeLevel] = splitGroupString
      return {
        activePrecinct,
        activeLevel,
      }
    }
    return {
      activeLevel: groupId,
      activePrecinct: '',
    }
  }, [])

  const interactiveClick = React.useCallback(
    (poly: Polygon) => {
      const { activeLevel } = checkGroupId(poly.groupId || '')
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: activeLevel,
        })
      )
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
      )

      if (!isConnected) {
        history.push('stages')
      }
    },
    [checkGroupId, precinctKeys, isConnected]
  )

  const getLabel = React.useCallback((poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label} ${poly.groupId}`
  }, [])

  const setInteractiveAction = React.useCallback(
    (areaViewMap: MapContent) => ({
      ...areaViewMap,
      polygons: areaViewMap.polygons
        .filter((poly) =>
          findRelevantStage(poly.groupId?.split('-')?.[1] || poly.groupId)
        )
        .map((poly) => ({
          ...poly,
          label: getLabel(poly),
          isHidden: getStageLotCount(poly) === 0,
          subLabel: poly?.subLabel
            ? `${getStageLotCount(poly)} ${poly.subLabel}`
            : '',
          postFix: `- Available Lots (${getAvailableLotCount(poly)})`,
          onClick: () => interactiveClick(poly),
          markerColour: levelMarkerColour,
        })),
    }),
    [
      findRelevantStage,
      getStageStatus,
      getLabel,
      getStageLotCount,
      getAvailableLotCount,
      interactiveClick,
      levelMarkerColour,
      statusLabels,
    ]
  )

  const precinct = React.useMemo(() => {
    if (!(storeActivePrecinct === '' || storeActivePrecinct)) {
      return []
    }
    if (!storePrecincts[storeActivePrecinct]) {
      return (
        Object.values(storePrecincts)?.[0]?.map((res) =>
          setInteractiveAction(res)
        ) || []
      )
    }
    return (
      storePrecincts[storeActivePrecinct]?.map((res) =>
        setInteractiveAction(res)
      ) || []
    )
  }, [storeActivePrecinct, storePrecincts, setInteractiveAction, matrixData])

  const handleClick = () => {
    const facadeLength = precinct.length - 1
    if (activeFacade < facadeLength) {
      setFacade(activeFacade + 1)
    } else {
      setFacade(0)
    }
  }

  const handleFaceController = (facadeHash: string) => {
    if (facadeControl !== facadeHash) {
      handleClick()
      setFacadeControl(facadeHash)
    }
  }

  const handleStageControl = (argStage: string, activePrecinct: string) => {
    if (activePrecinct) {
      if (
        precinct[activeFacade]?.polygons?.find(
          (res) => res.groupId === `${activePrecinct}-${argStage}`
        )
      ) {
        canvasRef?.current?.artificialTrigger(`${activePrecinct}-${argStage}`)
        return
      }
    }
    canvasRef?.current?.artificialTrigger(argStage)
  }

  const handleActivePrecinctControl = (activePrecinct: string) => {
    dispatch(
      setByFlag({
        flag: HouseAndLandActiveKeyType.ActivePrecinctId,
        value: activePrecinct,
      })
    )
  }

  React.useEffect(() => {
    if (!storeActivePrecinct && !session?.connected) {
      history.push('area-view-house-and-land')
    }
  }, [storeActivePrecinct, session?.connected])

  React.useEffect(() => {
    if (!rendering) {
      canvasRef?.current?.setPolyActive()
    }
  }, [precinct, rendering])

  React.useEffect(() => {
    if (interactivePayload.status === PENDING_STATUS) {
      setImageLoaded(false)
    }

    if (interactivePayload.status === FULFILLED_STATUS) {
      setHasLoaded(true)
    }
  }, [interactivePayload.status])

  React.useEffect(() => {
    if (prevActivePrecinctRef.current === storeActivePrecinct) {
      return
    }
    prevActivePrecinctRef.current = storeActivePrecinct
    setTimeout(() => {
      canvasRef?.current?.setCanvas()
    }, 100)
  }, [storeActivePrecinct])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: {
          activePrecinctId: firebaseActivePrecinct,
          filterPopup,
          activeFacadeId: fireStoreActiveFacade,
          activeStageId: firebaseActiveStageId,
          lotFilter,
        },
      } = session

      if (connected) {
        toggleFilter(filterPopup)
        if (fireStoreActiveFacade && renderCanvas) {
          handleFaceController(fireStoreActiveFacade)
        }
        if (firebaseActiveStageId && renderCanvas) {
          handleStageControl(firebaseActiveStageId, firebaseActivePrecinct)
        }
        dispatch(setFilter(lotFilter))
        handleActivePrecinctControl(firebaseActivePrecinct)
        setIsConnected(connected)
      }
    }
  }, [session])

  React.useEffect(() => {
    setRenderCanvas(true)
  }, [])

  const dataHandlerPayload = React.useMemo(() => {
    if (interactivePayload.status === UNINITIALIZED_STATUS) {
      return matrixPayload
    }

    return interactivePayload
  }, [interactivePayload, matrixPayload])

  return (
    <Container>
      <>
        <DataHandler
          payload={{
            ...dataHandlerPayload,
            status: hasLoaded ? FULFILLED_STATUS : dataHandlerPayload.status,
            data: precinctKeys,
          }}
          skeletonFrame={<PrecinctSkeleton />}
        >
          <div
            className={`absolute left-5 top-5 z-20 ${
              isConnected ? 'invisible' : ''
            }`}
          >
            <IdleTimeHandler>
              <div className="flex items-center gap-4">
                <Link
                  to="area-view-house-and-land"
                  className="rounded bg-white p-1 drop-shadow-40"
                >
                  <ArrowSvg className="h-8 w-8" strokeColor="#000" />
                </Link>
                <button
                  data-testid="toggle-filter"
                  onClick={() => toggleFilter(!isFilterOpen)}
                  type="button"
                  className="rounded bg-white p-2.5 drop-shadow-40"
                >
                  <FilterSvg className="h-5 w-5" />
                </button>
                {precinctList.length > 1 && (
                  <button
                    data-testid="toggle-filter"
                    onClick={() => toggleFilter(!isFilterOpen)}
                    type="button"
                    className="flex items-center gap-1.5 rounded bg-white p-2 drop-shadow-40"
                  >
                    <MapPinSvg />
                    <span>{storeActivePrecinct}</span>
                  </button>
                )}
              </div>
            </IdleTimeHandler>
          </div>

          <ImageHandler
            key={precinct[activeFacade]?.image}
            url={precinct[activeFacade]?.image}
            type="new"
            className="background-cover image-blur absolute inset-0 z-2"
            noSpliceUrl
            showFallbackImage={false}
            bgProps={{
              gradiant: 0.5,
            }}
          />

          <div className="absolute inset-0 z-3">
            <div
              ref={canvasContainerRef}
              className={`relative m-auto flex h-full w-full items-center justify-center ${
                isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {canvasContainerRef && renderCanvas && precinct[activeFacade] && (
                <CanvasInteractive
                  id="level-canvas"
                  ref={canvasRef}
                  canvasData={precinct[activeFacade]}
                  parentRef={canvasContainerRef}
                  hasLabel={!isConnected}
                  labelPrefix="Stage:"
                  isRendering={setRendering}
                  theme={{
                    brandColour: themeData.mainColour || '',
                    font: themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              )}
            </div>
          </div>
        </DataHandler>

        <FilterPopup
          isOpen={isFilterOpen}
          toggle={toggleFilter}
          activePrecinct={activePrecinct}
          setActivePrecinct={setActivePrecinct}
          hideStages
        />
      </>
    </Container>
  )
}

const findFilterStages = (
  matrixData: MatrixDataCollectionInterface,
  lotFilter: LotFilterInterface,
  activePrecinct: string,
  availableStatusLabel: string
): StageCollectionInterface => {
  const activeMatrixData = matrixData[activePrecinct]
  if (!activeMatrixData) {
    return {}
  }
  if (!lotFilter.apply) {
    return activeMatrixData.stages
  }
  const filteredMatrixData = filterPackageSummary(
    activeMatrixData,
    {
      ...lotFilter,
      storey: lotFilter.storey.map((item) => Number(item)),
    },
    availableStatusLabel
  )
  if (!filteredMatrixData) {
    return {}
  }
  return filteredMatrixData.stages || {}
}

export default connect(
  ({
    lotFilter,
    firestore: { session },
    interactivePlan: { precinct },
    houseAndLand: { precinctList, activePrecinctId, matrixData },
    projectConfig: {
      markerColour: { levels: levelMarkerColour },
      statusLabels,
    },
    projectIdentity,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    precinct,
    precinctList,
    storeActivePrecinct: activePrecinctId,
    matrixData,
    filterStages: findFilterStages(
      matrixData,
      lotFilter,
      activePrecinctId,
      statusLabels?.available
    ),
    lotFilter,
    levelMarkerColour,
    statusLabels,
  })
)(Precinct)

import React from 'react'

import { SvgProps } from './types'

function EllipsisHorizontalSvg({
  width,
  height,
  className,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      className={`${className}`}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 24 24"
      strokeWidth={stroke || 1.5}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  )
}
export default EllipsisHorizontalSvg

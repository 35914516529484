import React, { forwardRef } from 'react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

export interface Layer {
  src: string
  visible: boolean
  controls: boolean
  groupId: string
  type: string
  animation?: string
  baseImageOverride?: string
}

interface ComponentProps {
  id: string
  background: string
  layers: Array<Layer>
  heightClass?: string
}

export interface MapRefInterface {
  setMap(): void
}

const TYPE_ANIMATION = 'animation'
const TYPE_MARKER = 'marker'

const MapStack = forwardRef<MapRefInterface | undefined, ComponentProps>(
  ({ id, background, layers, heightClass = 'h-screen' }) => {
    const getImageSrc = (url: string) =>
      AssetHandler({
        url,
        type: 'new',
        noSpliceUrl: true,
      })

    const filteredLayers = (type: string): Array<Layer> =>
      layers.filter((layer: Layer) => layer.visible && layer.type === type)

    return (
      <div id={id} className={`relative w-full ${heightClass}`}>
        <img
          className="h-full w-full"
          src={getImageSrc(background)}
          alt="map-background"
        />
        {filteredLayers(TYPE_ANIMATION).map((animationLayer: Layer) => (
          <div key={animationLayer.groupId}>
            {animationLayer.src && (
              <img
                alt={animationLayer.src}
                className="absolute left-0 top-0 z-5 h-full w-full"
                src={getImageSrc(animationLayer.src || '')}
              />
            )}
            {animationLayer.animation && (
              <img
                alt={animationLayer.animation}
                className="absolute left-0 top-0 z-5 h-full w-full"
                src={getImageSrc(animationLayer.animation || '')}
              />
            )}
          </div>
        ))}
        {filteredLayers(TYPE_MARKER).map((markerLayer: Layer) => (
          <img
            key={markerLayer.groupId}
            alt={markerLayer.src}
            className="absolute left-0 top-0 z-5 h-full w-full"
            src={getImageSrc(markerLayer.src || '')}
          />
        ))}
      </div>
    )
  }
)

export default MapStack

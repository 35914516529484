import React from 'react'

import { SvgProps } from './types'

function PanoramaSvg({
  width,
  height,
  className,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      className={className}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 18 12"
    >
      <path
        d="M16.6533 1.53141V10.4677C16.6529 10.5524 16.6324 10.6357 16.5936 10.7109C16.5547 10.7861 16.4985 10.851 16.4296 10.9002C16.3608 10.9494 16.2812 10.9816 16.1975 10.9941C16.1138 11.0066 16.0283 10.9991 15.9481 10.9721C14.5193 10.4912 11.2173 9.47809 8.82667 9.47809C6.43606 9.47809 3.13407 10.4921 1.70527 10.9712C1.62504 10.9982 1.53955 11.0058 1.45583 10.9933C1.37211 10.9808 1.29255 10.9486 1.2237 10.8993C1.15485 10.8501 1.09867 10.7852 1.05978 10.71C1.02089 10.6348 1.0004 10.5515 1 10.4669V1.53228C1 1.16877 1.3609 0.913099 1.70527 1.02789C3.13407 1.50793 6.43606 2.52104 8.82667 2.52104C11.2173 2.52104 14.5193 1.50706 15.9481 1.02789C16.0283 1.00091 16.1138 0.993364 16.1975 1.00587C16.2812 1.01837 16.3608 1.05056 16.4296 1.0998C16.4985 1.14904 16.5547 1.21392 16.5936 1.2891C16.6324 1.36429 16.6529 1.44676 16.6533 1.53141Z"
        strokeWidth={stroke || 1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PanoramaSvg

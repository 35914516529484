import React from 'react'
import { useDispatch } from 'react-redux'

import InputHandler from '@src/utilities/form-util'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'

import { GenericModalCard as ModalCard } from '@components/modals'

import { ChevronSvg } from '@svg/react'

interface AassetHandlerProps {
  port: string
}

function LocalAssetHandler({ port }: AassetHandlerProps) {
  const dispatch = useDispatch()

  const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535

  const [toggled, setToggleModal] = React.useState(false)
  const [inputPortValue, setInputPortValue] = React.useState({
    value: port,
    isValid: true,
    message: '',
  })

  const isPortValid = (value: string) =>
    Number(value) > 0 && Number(value) <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT

  const handleSavePort = () => {
    dispatch(setProjectIdentity({ assetPort: inputPortValue.value }))
  }

  const resetInputPort = () =>
    setInputPortValue({
      value: '',
      isValid: true,
      message: '',
    })

  const handleRemovePort = () => {
    resetInputPort()
    dispatch(setProjectIdentity({ assetPort: '' }))
  }

  React.useEffect(() => {
    setInputPortValue({
      ...inputPortValue,
      value: port,
    })
  }, [toggled])

  return (
    <>
      <ModalCard
        headerLabel="Local Assets"
        okLabel="Save"
        toggle={(status: boolean) => setToggleModal(status)}
        cancelAction={() => resetInputPort()}
        okAction={() => handleSavePort()}
        isVisible={toggled}
        disableOk={inputPortValue.value.length === 0 || !inputPortValue.isValid}
        hideOk={port.length > 0}
      >
        <div className="px-4 text-left">
          <p>
            For improved performance you can choose to use an asset pack hosted
            on your local machine:
          </p>
          <p className="pt-4">
            <b>Port:</b>
          </p>
        </div>
        <div
          className={`mx-4 my-3 flex items-center justify-center rounded border text-lg
          ${
            !inputPortValue.isValid && inputPortValue.value
              ? 'border-red-700'
              : 'border-gray-300'
          } 
        `}
        >
          <input
            onChange={(event) =>
              InputHandler(
                event,
                setInputPortValue,
                'The given port is invalid',
                isPortValid
              )
            }
            className="w-full border-none focus:ring-0"
            placeholder="Enter you local asset port"
            type="text"
            value={inputPortValue.value}
          />
          {port && (
            <button
              onClick={() => handleRemovePort()}
              type="button"
              className="ml-auto p-2 text-red-700"
            >
              Remove
            </button>
          )}
        </div>
        <div className="px-4 text-left text-sm">
          {!inputPortValue.isValid && inputPortValue.value && (
            <span className="ml-auto text-red-700">
              {inputPortValue.message}
            </span>
          )}
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggled)}
        type="button"
        className="flex w-full items-center rounded border border-2 border-gray-300 p-3 px-5 text-left"
      >
        <span className="text-sm">Use local asset pack</span>
        <ChevronSvg
          className="ml-auto h-5 w-5 py-1"
          stroke={4}
          rotate="right"
        />
      </button>
    </>
  )
}

export default LocalAssetHandler

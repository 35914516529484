import React from 'react'

import { SvgProps } from './types'

function PlaySvg({ width, height, className }: SvgProps) {
  return (
    <svg
      width={width || '15'}
      height={height || '17'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 15 17"
    >
      <path
        d="M1.906 1.037C1.81491 0.982988 1.71114 0.95405 1.60524 0.953125C1.49934 0.9522 1.39508 0.979321 1.30306 1.03173C1.21104 1.08414 1.13452 1.15998 1.0813 1.25153C1.02807 1.34308 1.00002 1.4471 1 1.553V15.447C1.00002 15.5529 1.02807 15.6569 1.0813 15.7485C1.13452 15.84 1.21104 15.9159 1.30306 15.9683C1.39508 16.0207 1.49934 16.0478 1.60524 16.0469C1.71114 16.0459 1.81491 16.017 1.906 15.963L13.629 9.016C13.7185 8.96287 13.7927 8.88736 13.8442 8.79689C13.8957 8.70642 13.9228 8.60411 13.9228 8.5C13.9228 8.39589 13.8957 8.29357 13.8442 8.2031C13.7927 8.11263 13.7185 8.03712 13.629 7.984L1.906 1.037Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlaySvg

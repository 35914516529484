import React, { FormEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { setRoom } from '@store/actionSlices/appConfig'
import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { TokenPayloadInterface, setToken } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container'

import {
  useAuthUserMutation,
  useAuthViaTokenMutation,
} from '@api/authentication'

import InputHandler from '@utilities/form-util'
import { getQueryStringParams } from '@utilities/helper'
import ResetUtil from '@utilities/reset-util'

import { getClientInformation } from '@adUtilities/client-information'

export interface LoginProps {
  projectIdentity: ProjectIdentity
}

function Login({ projectIdentity }: LoginProps) {
  const dispatch = useDispatch()
  const location = useLocation()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })
  const resetUtil = ResetUtil()

  const [authenticateUser, { data, error }] = useAuthUserMutation()
  const [authenticateViaToken, { data: tokenData, error: tokenError }] =
    useAuthViaTokenMutation()
  const [serverErrorMessage, setServerErrorMessage] = React.useState('')

  const [formValid, setFormValid] = React.useState(false)

  const [authFail, setAuthFail] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const [projectName, setProjectName] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [password, setPassword] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [email, setEmail] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  React.useEffect(() => {
    if (
      !password.isValid ||
      !email.isValid ||
      !password.value ||
      !email.value ||
      isLoading
    ) {
      setFormValid(false)
      return
    }
    setFormValid(true)
  }, [password, email, isLoading])

  const authProcedure = (tokenPayload: TokenPayloadInterface) => {
    setIsLoading(false)
    dispatch(setToken(tokenPayload))
  }

  const triggerLogin = async () => {
    const clientInformation = await getClientInformation()
    authenticateUser({
      project_canonical_name: projectName.value,
      password: password.value,
      email: email.value,
      clientInformation: { ...clientInformation, client: 'showcase' },
    })
  }

  const login = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    const exists = await firebaseControlQuery.isProjectEnabled(
      projectName.value
    )
    if (exists) {
      triggerLogin()
      setAuthFail(false)
      return
    }
    setIsLoading(false)
    setAuthFail(true)
  }

  const validateSessionArray = (sessionListJson: string) => {
    try {
      const sessionListArray = JSON.parse(sessionListJson)
      if (sessionListArray instanceof Array) {
        return sessionListArray.filter(
          (sesh: any) => sesh?.key && sesh?.project
        )
      }
    } catch (e) {
      console.error('Invalid Session List.')
    }
    return []
  }

  const handleSessionListFireStore = async (
    sessionId: { project: string; key: string }[]
  ) => {
    await Promise.all(
      sessionId.map(async ({ project, key }) => {
        if (projectName.value === project) {
          return
        }
        await firebaseControlQuery.createSession({
          projectName: project,
          sessionId: key,
        })
      })
    )
  }

  const handleAutoAuthProcedure = async () => {
    if (tokenData && tokenData.data) {
      const {
        data: {
          user,
          project: { canonical_name: activeProject },
        },
      } = tokenData

      const token = user as TokenPayloadInterface

      const { sessionKey, room, sessionList, masterKey } = getQueryStringParams(
        location.search
      )

      const sessionIdArray = validateSessionArray(sessionList)

      if (sessionKey) {
        sessionIdArray.push({
          key: sessionKey,
          project: activeProject,
        })
      }

      await handleSessionListFireStore(sessionIdArray)
      if (room) dispatch(setRoom(room))

      dispatch(
        setProjectIdentity({
          projectName: tokenData.data.project.canonical_name,
          projectId: tokenData.data.project.id,
          projectLabel: tokenData.data.project.name,
          sessionId: sessionIdArray,
          masterKey: masterKey ?? '',
        })
      )
      authProcedure(token)
    }
  }

  React.useEffect(() => {
    if (data && data.data) {
      dispatch(
        setProjectIdentity({
          projectName: data.data.project.canonical_name,
          projectId: data.data.project.id,
          projectLabel: data.data.project.name,
          sessionId: [],
          masterKey: '',
        })
      )
      authProcedure(data.data.user as TokenPayloadInterface)
    }
  }, [data])

  React.useEffect(() => {
    handleAutoAuthProcedure()
  }, [tokenData])

  React.useEffect(() => {
    if (error) {
      if ('data' in error) {
        setServerErrorMessage(
          JSON.parse(JSON.stringify(error.data)).message ?? ''
        )
      }
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [error])

  React.useEffect(() => {
    if (tokenError) {
      if ('data' in tokenError) {
        setServerErrorMessage(
          JSON.parse(JSON.stringify(tokenError.data)).message ?? ''
        )
      }
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [tokenError])

  React.useEffect(() => {
    resetUtil.resetStore()
    localStorage.removeItem('offlineMode')
  }, [])

  const autoAuthenticate = async (projectId: string) => {
    setIsLoading(true)
    setAuthFail(false)

    const exists = await firebaseControlQuery.isProjectEnabled(projectId)
    if (!exists) {
      setIsLoading(false)
      setAuthFail(true)
      return
    }

    const clientInformation = await getClientInformation()
    const { authenticate } = getQueryStringParams(location.search)
    if (authenticate && projectId) {
      authenticateViaToken({
        projectId,
        token: authenticate,
        clientInformation: { ...clientInformation, client: 'showcase' },
      })
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    const paths = location.pathname.split('/').filter((res: string) => res)
    if (paths.length) {
      setProjectName({
        ...projectName,
        value: paths[0],
      })
      autoAuthenticate(paths[0])
    }
  }, [])

  return (
    <Container
      background={{
        url: '/images/devsuite-bg.jpg',
        type: 'new',
        noSpliceUrl: true,
      }}
      disableNavigation
    >
      <div className="flex h-screen w-full items-center justify-center">
        <form className="basic-white-form flex flex-col gap-8" onSubmit={login}>
          <div className="text-center">Showcase</div>

          <div>
            <label className="input-label" htmlFor="projectName">
              Display ID
            </label>
            <input
              onChange={(e) => InputHandler(e, setProjectName)}
              className={`focus:shadow-outline focus:outline-none ${
                projectName.isValid ? 'input-gray' : 'input-gray-error'
              }`}
              id="projectName"
              type="text"
              placeholder="id-development"
              value={projectName.value}
              disabled={isLoading}
              required
            />
            {projectName.message && (
              <div>
                <p className="text-xs italic text-red-500">
                  {projectName.message}
                </p>
              </div>
            )}
          </div>

          <div>
            <label className="input-label" htmlFor="username">
              Username
            </label>
            <input
              onChange={(e) => InputHandler(e, setEmail)}
              className={`focus:shadow-outline focus:outline-none ${
                email.isValid ? 'input-gray' : 'input-gray-error'
              }`}
              id="username"
              type="email"
              placeholder="example@gmail.com"
              value={email.value}
              disabled={isLoading}
              required
            />
            {email.message && (
              <div>
                <p className="text-xs italic text-red-500">
                  Invalid Email Format.
                </p>
              </div>
            )}
          </div>

          <div>
            <label className="input-label" htmlFor="password">
              Password
            </label>
            <input
              onChange={(e) => InputHandler(e, setPassword)}
              className={`focus:shadow-outline focus:outline-none ${
                password.isValid ? 'input-gray' : 'input-gray-error'
              }`}
              id="password"
              type="password"
              name="password"
              placeholder="********"
              autoComplete="on"
              value={password.value}
              disabled={isLoading}
              required
            />
            {password.message && (
              <div>
                <p className="text-xs italic text-red-500">
                  {password.message}
                </p>
              </div>
            )}
            {!password.message && authFail && (
              <div>
                <p className="my-2 text-xs italic text-red-500">
                  {serverErrorMessage === 'Invalid Display ID'
                    ? 'Invalid Display ID'
                    : 'You entered invalid user credentials.'}
                </p>
              </div>
            )}
          </div>

          <div>
            <button
              className={`focus:shadow-outline w-full rounded px-4 py-2 text-white focus:outline-none ${
                formValid
                  ? 'bg-sky-500 hover:bg-sky-700'
                  : 'bg-gray-500 hover:bg-gray-700'
              }`}
              type="submit"
              disabled={!formValid || isLoading}
            >
              {isLoading && (
                <div
                  style={{ borderTopColor: 'transparent' }}
                  className="border-white-400 mr-1 inline-block h-3 w-3 animate-spin rounded-full border-2 border-dotted"
                />
              )}
              Sign In
            </button>
          </div>
        </form>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Login)

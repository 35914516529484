import React from 'react'

const events = ['mousemove', 'click', 'keyup', 'touchstart', 'touchmove']

interface IdleTimeHandlerProps {
  timeoutInSeconds: number
  isConnected: boolean
  isIdleActive: boolean
}

export const useIdleTimeHandler = ({
  timeoutInSeconds,
  isConnected,
  isIdleActive,
}: IdleTimeHandlerProps) => {
  const timerId = React.useRef<NodeJS.Timeout | null>(null)
  const [isAppIdle, setIsAppIdle] = React.useState<boolean>(false)

  const resetTimer = React.useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = null
  }, [])

  const handleSetAppIdle = React.useCallback(() => {
    resetTimer()
    setIsAppIdle(true)
  }, [])

  const setTimer = React.useCallback(() => {
    timerId.current = setTimeout(handleSetAppIdle, timeoutInSeconds * 1000)
  }, [timeoutInSeconds])

  const onUserInteraction = () => {
    setIsAppIdle(false)

    resetTimer()

    if (timeoutInSeconds) {
      setTimer()
    }
  }

  React.useEffect(() => {
    if (timeoutInSeconds) {
      setTimer()

      events.forEach((event: string) =>
        window.addEventListener(event, onUserInteraction, true)
      )
    }

    return () => {
      resetTimer()
      events.forEach((event: string) =>
        window.removeEventListener(event, onUserInteraction, true)
      )
    }
  }, [timeoutInSeconds])

  if (isConnected || !isIdleActive) return false

  return !timeoutInSeconds ? isIdleActive && isAppIdle : isAppIdle
}

export default {}

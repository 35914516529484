import React from 'react'

function NoUnitCard() {
  return (
    <div className="mt-4 rounded-md bg-white px-6 py-4">
      No Units to Display
    </div>
  )
}

export default NoUnitCard

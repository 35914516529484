const getDataFromLocalstorage = (key: string) => {
  const jsonData = localStorage.getItem(key)
  if (!jsonData) {
    return null
  }

  return JSON.parse(jsonData) || null
}

const getActiveProjectCanonicalName = (): string => {
  const projectIdentityObject = getDataFromLocalstorage('projectIdentity')
  return projectIdentityObject?.projectName || ''
}

const getAssetPort = (): string => {
  const projectIdentityObject = getDataFromLocalstorage('projectIdentity')
  return projectIdentityObject?.assetPort || ''
}

const getAssetHost = (): string => {
  const projectIdentityObject = getDataFromLocalstorage('projectIdentity')
  return projectIdentityObject?.assetHost || ''
}

export { getActiveProjectCanonicalName, getAssetPort, getAssetHost }

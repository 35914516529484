import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import type { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Skeleton from '@components/skeleton'

import {
  Level,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  InteractivePlanData,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import { Unit } from '@adUtilities/types/apartment'

export interface ThreeDViewerProps {
  threeDViewerUrl: string
  projectName: string
  interactivePlan: InteractivePlanData
  building: BuildingInterface
}

function ThreeDViewer({
  threeDViewerUrl,
  projectName,
  interactivePlan,
  building,
}: ThreeDViewerProps) {
  const history = useHistory()
  const dispatch = useDispatch()

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName },
    { selectFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const getCorrectBlockValue = (
    buildingData: BuildingInterface,
    activeBlock: string,
    activeLevel: string
  ) => {
    const level = buildingData.levels.find(
      (lvl: Level) => activeLevel === lvl.level
    )
    if (!level) {
      return ''
    }
    const unit = level.data.find(
      (unt: Unit) => (unt?.blockId || '').replace(/\s/g, '') === activeBlock
    )
    if (!unit) {
      return ''
    }
    return unit.blockId || ''
  }

  const handleSnapLoaderEvent = (
    e: MessageEvent,
    buildingData: BuildingInterface
  ) => {
    try {
      const { name } = JSON.parse(e.data)
      if (name) {
        const [activeBlock, activeLevel] = name.split('_')

        dispatch(
          setByFlag({
            flag: 'activeBlock',
            value: getCorrectBlockValue(buildingData, activeBlock, activeLevel),
          })
        )

        dispatch(
          setByFlag({
            flag: 'activeLevel',
            value: activeLevel || '',
          })
        )

        if (activeLevel) {
          history.push('building')
          return
        }

        console.log('Incorrect building data.')
      }
    } catch (error) {
      console.log('Incorrect format was given in mapping.')
    }
  }

  const handleInspaceEvent = (e: MessageEvent) => {
    const [activeBlock, activeLevel] = e.data?.route.split('_') ?? []

    if (e.data?.hasBlock && activeBlock) {
      dispatch(
        setByFlag({
          flag: 'activeBlock',
          value: activeBlock,
        })
      )
    }

    if (activeLevel) {
      dispatch(
        setByFlag({
          flag: 'activeLevel',
          value: activeLevel,
        })
      )
      history.push('building')
      return
    }

    console.log('Incorrect building data.')
  }

  const messageEventHandler = React.useCallback(
    (event: MessageEvent) => {
      if (event.data.child) {
        handleInspaceEvent(event)
        return
      }
      if (event.data) {
        handleSnapLoaderEvent(event, building)
      }
    },
    [building]
  )

  React.useEffect(() => {
    window.parent.addEventListener('message', messageEventHandler)
  }, [building])

  React.useEffect(() => {
    const { maps } = interactivePayload

    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload])

  React.useEffect(() => {
    if (!threeDViewerUrl) {
      history.push('area-view')
    }
  }, [threeDViewerUrl])

  React.useEffect(() => {
    const { building: buildingData, blockOrders: blockOrdersData } =
      buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          blockOrders: blockOrdersData,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload, building])

  React.useEffect(
    () => () => {
      window.parent.removeEventListener('message', messageEventHandler)
    },
    []
  )

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePayload,
          data: true,
          apiData: interactivePayload.maps?.areaView,
        }}
        skeletonFrame={<Skeleton />}
      >
        <iframe
          title="threeDViewer"
          src={threeDViewerUrl}
          className="h-full w-full overflow-hidden border-0"
          height="100%"
          width="100%"
        ></iframe>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectConfig,
    projectIdentity: { projectName },
    interactivePlan,
    building,
  }: RootStateTypeExtra) => ({
    threeDViewerUrl: projectConfig.threeDViewerUrl,
    projectName,
    interactivePlan,
    building,
  })
)(ThreeDViewer)

import React from 'react'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { ShortlistState } from '@store/actionSlices/shortlist'
import type { Channel, ProjectIdentity } from '@store/types'

interface ComponentProps {
  isActive?: boolean
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
  lightUpBuildingModelOnFilter: boolean
  filteredApplied: boolean
  filteredItems: Array<{ name: string; type: string }>
  activeLevel: string
  activeUnit: string
  shortlists: ShortlistState['shortlists']
}

const LightMapHandler = ({
  isActive = true,
  projectIdentity,
  channels,
  lightUpBuildingModelOnFilter,
  filteredApplied,
  filteredItems,
  activeLevel,
  activeUnit,
  shortlists,
}: ComponentProps): void => {
  const CHANNEL_TYPE_SHORTLIST = 'shortlist'
  const CHANNEL_TYPE_UNIT = 'unit'
  const CHANNEL_TYPE_LEVEL = 'level'

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    lightKey: projectIdentity.lightMap,
    sessionKeys: projectIdentity.sessionId,
  })

  const handleFilteredItemMap = (
    filteredApplied: boolean,
    data: Array<{ name: string; type: string }>
  ) => {
    if (channels.length === 0) {
      return
    }

    firebaseControlQuery.updateFilterStateInLightMap(filteredApplied)

    if (!filteredApplied) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      const foundItem = data.find(
        (item) => item.name === channel.name && item.type === channel.type
      )

      return {
        ...channel,
        status: foundItem !== undefined,
      }
    })

    firebaseControlQuery.updateLightChannel(updatedChannels)
  }

  const handleActiveLevelMap = (level: string) => {
    if (channels.length === 0) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_LEVEL) {
        return {
          ...channel,
          status: false,
        }
      }

      return {
        ...channel,
        status: channel.name === level,
      }
    })

    firebaseControlQuery.updateLightChannel(updatedChannels)
  }

  const handleActiveUnitMap = (unit: string) => {
    if (channels.length === 0) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_UNIT) {
        return {
          ...channel,
          status: false,
        }
      }
      return {
        ...channel,
        status: channel.name === unit,
      }
    })

    firebaseControlQuery.updateLightChannel(updatedChannels)
  }

  const handleShortlistedUnitMap = () => {
    if (channels.length === 0) {
      return
    }

    if (
      channels.filter((res) => res.type === CHANNEL_TYPE_SHORTLIST).length === 0
    ) {
      return
    }

    if (shortlists.length === 0) {
      const updatedChannels = channels.map((channel: Channel) => {
        if (channel.type === CHANNEL_TYPE_SHORTLIST) {
          return {
            ...channel,
            status: false,
          }
        }
        return channel
      })

      firebaseControlQuery.updateLightChannel(updatedChannels)
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_SHORTLIST) {
        return channel
      }

      const foundItem = shortlists.filter(
        (shortlistedUnit) => shortlistedUnit.propertyName === channel.name
      )

      return {
        ...channel,
        status: foundItem.length > 0,
      }
    })

    firebaseControlQuery.updateLightChannel(updatedChannels)
  }

  React.useEffect(() => {
    if (!isActive) {
      return
    }
    if (lightUpBuildingModelOnFilter && filteredApplied) {
      return
    }

    if (activeUnit) {
      handleActiveUnitMap(activeUnit)
      return
    }
    handleActiveLevelMap(activeLevel)
  }, [activeLevel, activeUnit, filteredApplied, isActive])

  React.useEffect(() => {
    if (!isActive) {
      return
    }
    if (!lightUpBuildingModelOnFilter) {
      return
    }

    handleFilteredItemMap(filteredApplied, filteredItems)
  }, [filteredItems, filteredApplied, isActive])

  React.useEffect(() => {
    if (!isActive) {
      return
    }
    if (lightUpBuildingModelOnFilter) {
      return
    }

    handleShortlistedUnitMap()
  }, [shortlists, lightUpBuildingModelOnFilter, isActive])
}

export default LightMapHandler

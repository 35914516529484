import { ANY, Status } from './constants/common'
import { isValueInRange } from './helper'
import {
  Unit,
  UnitFilterOptions,
  UnitFilterOptionsConfigurations,
} from './types/apartment'
import { Configurations, MinMaxInterface } from './types/common'

const isConfigMatch = (
  config: Configurations | undefined,
  filterConfig: UnitFilterOptionsConfigurations | undefined
): boolean => {
  if (!config || !filterConfig) {
    return true
  }

  const configFields: Array<keyof UnitFilterOptionsConfigurations> = [
    'bed',
    'study',
    'bath',
    'powderRoom',
    'car',
  ]

  return configFields.every((field) => {
    const configValue = filterConfig[field]

    if (!configValue) {
      return true
    }

    const pkgValue = Number(config?.[field] ?? 0)
    return isValueInRange(configValue, pkgValue)
  })
}

const isUnitPriceInRange = (
  unit: Unit,
  range: MinMaxInterface,
  showPrice: boolean
): boolean => {
  if (!showPrice) {
    return true
  }

  const { price } = unit.metas
  return price ? isValueInRange(range, price) : false
}

const filterUnit = (
  unit: Unit,
  filter: UnitFilterOptions,
  showPrice: boolean,
  availableStatusLabel: string
): boolean => {
  const { range, configurations, aspect, unitType, showAvailable } = filter

  const matchesPrice = isUnitPriceInRange(unit, range, showPrice)

  const matchesConfig = isConfigMatch(unit.metas.configurations, configurations)

  const availableStatuses = [availableStatusLabel, Status.Available]
  const isUnitAvailable = availableStatuses.includes(unit.metas.status)

  const matchesAspect =
    aspect && aspect.length && unit.metas?.aspect
      ? aspect.includes(unit.metas.aspect.toLocaleLowerCase())
      : true

  const matchesUnitType =
    unitType && unitType !== ANY && unit.metas?.classification
      ? unitType === unit.metas.classification
      : true

  if (showAvailable) {
    return (
      isUnitAvailable &&
      matchesPrice &&
      matchesConfig &&
      matchesAspect &&
      matchesUnitType
    )
  }

  return matchesPrice && matchesConfig && matchesAspect && matchesUnitType
}

export default filterUnit

import { Dot } from 'pure-react-carousel'
import React from 'react'

import { ThumbnailInterface } from '@store/types'

import ImageHandler from '@components/image-handler'

export interface ThumbnailDotInterface {
  item: ThumbnailInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
  disabled?: boolean
}

function ThumbnailDot({
  item,
  index,
  currentSlide,
  setCurrentSlide,
  disabled,
}: ThumbnailDotInterface) {
  return (
    <div className="grid justify-items-center">
      <Dot
        className="ad-dot h-[85px] w-[106px] rounded-lg bg-transparent drop-shadow-40 transition-size"
        slide={index}
        onClick={() => setCurrentSlide(index)}
        disabled={disabled}
      >
        <ImageHandler
          className="relative h-full w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
          url={item?.src}
          type={item?.type}
          noSpliceUrl={item?.noSpliceUrl}
          bgProps={{
            gradiant: item?.gradiant ?? 0,
          }}
        >
          <div
            className={`absolute left-0 top-0 h-full w-full rounded-lg bg-black transition-opacity ease-in-out ${
              currentSlide === index ? 'opacity-50' : 'opacity-0'
            }`}
          ></div>
        </ImageHandler>
      </Dot>
    </div>
  )
}

export default ThumbnailDot

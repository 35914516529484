import React from 'react'

import ImageHandler from '@components/image-handler'

export interface NoDataFoundProps {
  message?: string
}
const FALLBACK_IMAGE_URL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL || ''

function DataNotFound({ message = 'No data found.' }: NoDataFoundProps) {
  return (
    <ImageHandler
      url={FALLBACK_IMAGE_URL}
      type="new"
      className="background-cover"
      bgProps={{ gradiant: 0 }}
      alt={message}
    />
  )
}

export default DataNotFound

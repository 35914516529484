import React from 'react'

function LotViewSkeleton() {
  return (
    <div className="h-full w-full bg-mainColour">
      <div className="shimmer-container absolute left-0 top-0 z-1 h-full w-full"></div>
    </div>
  )
}

export default LotViewSkeleton

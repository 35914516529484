import { BedConfigInterface, ExtendedUnitFilterOptions } from '@store/types'

function countFilterChanges(
  oldObject: ExtendedUnitFilterOptions,
  newObject: ExtendedUnitFilterOptions
) {
  let changes = 0

  Object.keys(oldObject).forEach((key) => {
    if (
      typeof oldObject[key as keyof ExtendedUnitFilterOptions] === 'object' &&
      typeof newObject[key as keyof ExtendedUnitFilterOptions] === 'object'
    ) {
      if (key === 'configurations') {
        Object.keys(oldObject[key as keyof ExtendedUnitFilterOptions]).forEach(
          (configKey: keyof BedConfigInterface) => {
            const oldConfigValue =
              oldObject[key as keyof ExtendedUnitFilterOptions]
            const newConfigValue =
              newObject[key as keyof ExtendedUnitFilterOptions]
            const oldValue =
              oldConfigValue[configKey as keyof typeof oldConfigValue]
            const newValue =
              newConfigValue[configKey as keyof typeof newConfigValue]

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              changes += 1
            }
          }
        )
      } else if (
        JSON.stringify(oldObject[key as keyof ExtendedUnitFilterOptions]) !==
        JSON.stringify(newObject[key as keyof ExtendedUnitFilterOptions])
      ) {
        changes += 1
      }
    } else if (
      key !== 'apply' &&
      oldObject[key as keyof ExtendedUnitFilterOptions] !==
        newObject[key as keyof ExtendedUnitFilterOptions]
    ) {
      changes += 1
    }
  })

  return changes
}

export default countFilterChanges

import React from 'react'
import { useDispatch } from 'react-redux'

import { setInteractiveMap } from '@store/actionSlices/interactiveMap'

import { Layer, Stack } from '@api/interactive-map'

import { ArrowSvg, ChevronDoubleSvg, EyeSvg } from '@svg/react'

import LayerList from './layer-list'

interface LayerCategoryPanelProps {
  categories: Array<string>
  interactiveMap: Stack
  setLayerBackground: (arg: string) => void
  setCategoryBackground: (arg: string) => void
}

function LayerCategoryPanel({
  categories,
  interactiveMap,
  setLayerBackground,
  setCategoryBackground,
}: LayerCategoryPanelProps) {
  const dispatch = useDispatch()
  const [showLayerCategoryPanel, toggleLayerCategoryPanel] =
    React.useState(true)
  const [showCategoryDetails, toggleCategoryDetails] = React.useState(false)
  const [activeCategory, setActiveCategory] = React.useState('')

  const getActiveItem = (category: string) =>
    interactiveMap.layers.reduce(
      (count, layer) =>
        count + (layer.category === category && layer.visible ? 1 : 0),
      0
    )

  const handleSetCategoryBackgroundImage = (category: string) => {
    const hasVisibleLayer = interactiveMap.layers.find(
      (i) => i.category === category && i.visible
    )

    if (hasVisibleLayer) {
      return
    }
    const matchedLayer = interactiveMap.layers.find(
      (layer) =>
        layer.category === category &&
        layer.categoryDefault &&
        layer.baseImageOverride
    )
    if (matchedLayer) {
      setCategoryBackground(matchedLayer.baseImageOverride)
      return
    }
    setCategoryBackground('')
  }

  const handleToggleCategoryDetails = (toggle: boolean) => {
    toggleCategoryDetails(toggle)
    if (!toggle) setCategoryBackground('')
  }

  React.useEffect(() => {
    if (activeCategory) {
      if (!interactiveMap.storyMode) {
        return
      }

      const layers: Array<Layer> = interactiveMap.layers.map((layerItem) => ({
        ...layerItem,
        visible: false,
      }))

      setLayerBackground('')

      dispatch(
        setInteractiveMap({
          ...interactiveMap,
          layers,
        })
      )
    }
  }, [activeCategory])

  return showLayerCategoryPanel ? (
    <div className="absolute bottom-16 left-8 z-20 w-[18rem]">
      {showCategoryDetails ? (
        <LayerList
          toggleCategoryDetails={handleToggleCategoryDetails}
          activeCategory={activeCategory}
          interactiveMap={interactiveMap}
          setCategoryBackground={setCategoryBackground}
          setLayerBackground={setLayerBackground}
        >
          <button
            className="absolute -left-4 top-[41%] z-10 rounded-full bg-white p-2"
            type="button"
            onClick={() => {
              toggleLayerCategoryPanel(!showLayerCategoryPanel)
            }}
          >
            <ChevronDoubleSvg rotate="top" stroke={2} className="h-4 w-4" />
          </button>
        </LayerList>
      ) : (
        <div className="relative rounded-xl bg-white/60">
          <button
            className={`absolute -left-4 ${
              categories.length === 1 ? 'top-[20%]' : 'top-[41%]'
            } z-10 rounded-full bg-white p-2`}
            type="button"
            onClick={() => {
              toggleLayerCategoryPanel(!showLayerCategoryPanel)
            }}
          >
            <ChevronDoubleSvg rotate="top" stroke={2} className="h-4 w-4" />
          </button>
          {categories.map((category) => (
            <div
              role="button"
              tabIndex={0}
              key={category}
              onClick={() => {
                toggleCategoryDetails(!showCategoryDetails)
                setActiveCategory(category)
                handleSetCategoryBackgroundImage(category)
              }}
              onKeyDown={() => {}}
              className="flex items-center justify-between border-b border-gray-300 px-6 py-3 last:border-transparent"
            >
              <div className="flex items-center gap-2">
                <span className="text-sm font-semibold">{category}</span>
                {getActiveItem(category) ? (
                  <button
                    type="button"
                    className="flex items-center gap-2 rounded-xl bg-mainColour px-2 text-white"
                  >
                    <EyeSvg className="h-4 w-4 " />
                    <span className="text-sm">{getActiveItem(category)}</span>
                  </button>
                ) : (
                  ''
                )}
              </div>
              <button type="button">
                <ArrowSvg
                  className="h-6 w-6"
                  strokeColor="#000"
                  rotate="right"
                />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <button
      className="absolute left-4 top-2/4 z-20 rounded-full bg-white p-2"
      type="button"
      onClick={() => {
        toggleLayerCategoryPanel(!showLayerCategoryPanel)
      }}
    >
      <ChevronDoubleSvg stroke={2} rotate="down" className="h-4 w-4" />
    </button>
  )
}

export default LayerCategoryPanel

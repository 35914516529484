import React from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  HouseAndLandInterface,
  setByFlag,
} from '@store/actionSlices/houseAndLand'
import { resetFilter, setFilter } from '@store/actionSlices/lotFilter'
import { RootStateType } from '@store/store'
import {
  BedConfigInterface,
  HouseAndLandConfigurationInterface,
  LotFilterInterface,
  PriceRange,
} from '@store/types'

import DropDown from '@components/dropdown'
import IdleTimeHandler from '@components/idle-time-handler'
import ToggleSwitch from '@components/toggle-switch'

import { HouseAndLandActiveKeyType } from '@api/types/house-and-land-type'

import { CloseSvg } from '@svg/react'

import { MinMaxInterface } from '@adUtilities/types/common'
import {
  FilterOptionLotConfigurations,
  FilterOptionsConfigurations,
} from '@adUtilities/types/houseAndLand'

import RangeOptions from './range-options'

interface ComponentProps {
  isOpen: boolean
  toggle: (arg: boolean) => void
  activePrecinct: string
  setActivePrecinct: (arg: string) => void
  lotFilter: LotFilterInterface
  prices: PriceRange
  showPrice: boolean
  houseAndLand: HouseAndLandInterface
  aspects: Array<string>
  bedConfig: Array<BedConfigInterface>
  houseAndLandConfig: HouseAndLandConfigurationInterface
  hideStages?: boolean
  isFetchingStages?: boolean
  onChangePrecinct?: (arg: string) => void
}
function HouseAndLandFilterPopup({
  isOpen,
  toggle,
  activePrecinct,
  setActivePrecinct,
  lotFilter,
  prices,
  showPrice = true,
  houseAndLand,
  bedConfig,
  houseAndLandConfig,
  aspects,
  hideStages,
  isFetchingStages,
  onChangePrecinct,
}: ComponentProps) {
  const filterRef = React.useRef<HTMLDivElement>(null)
  const precincts = houseAndLand.precinctList
  const {
    lotFrontage: lotFrontageOptions,
    lotArea: lotAreaOptions,
    storey: storeyOptions,
    builder: builderOptions,
  } = houseAndLandConfig || {}
  const dispatch = useDispatch()

  const [isStageChange, setStageChange] = React.useState<boolean>(false)
  const [activeStage, setActiveStage] = React.useState<string>(
    houseAndLand.activeStageId
  )
  const [checkAnyStage, setAnyStage] = React.useState(lotFilter.anyStage)
  const [configurations, setConfigurations] =
    React.useState<FilterOptionsConfigurations>(lotFilter.configurations)
  const [lotConfigurations, setLotConfigurations] =
    React.useState<FilterOptionLotConfigurations>(lotFilter.lotConfiguration)
  const [builder, setBuilder] = React.useState<Array<string>>(lotFilter.builder)
  const [storey, setStorey] = React.useState<Array<number>>(lotFilter.storey)
  const [showAvailable, setShowAvailable] = React.useState(
    lotFilter.showOnlyAvailable
  )
  const packageSummary = houseAndLand.matrixData[activePrecinct]
  const precinctLength = React.useMemo(() => precincts.length, [precincts])

  const precinctOptions = React.useMemo(
    () =>
      precincts.map(({ label }) => ({
        label,
        value: label,
      })),
    [precincts]
  )

  const stageOptions = React.useMemo(
    () =>
      Object.keys(packageSummary?.stages ?? {})?.map((label) => ({
        label: `Stage ${label}`,
        value: label,
      })),
    [activePrecinct, packageSummary]
  )

  const handleBuilderOnChange = (value: string) => {
    if (builder.includes(value)) {
      dispatch(
        setFilter({
          ...lotFilter,
          builder: builder.filter((item) => item !== value),
        })
      )
    } else {
      dispatch(setFilter({ ...lotFilter, builder: [...builder, value] }))
    }
  }

  const handleStoreyOnChange = (value: number) => {
    if (storey.includes(value)) {
      dispatch(
        setFilter({
          ...lotFilter,
          storey: storey.filter((item) => item !== value),
        })
      )
    } else {
      dispatch(setFilter({ ...lotFilter, storey: [...storey, value] }))
    }
  }

  const handleAspectOnChange = (value: string) => {
    const lotAspect = lotConfigurations?.aspects
    if (lotAspect) {
      if (lotAspect.includes(value)) {
        const filterAspect = lotAspect.filter((item) => item !== value)
        dispatch(
          setFilter({
            ...lotFilter,
            lotConfiguration: { ...lotConfigurations, aspects: filterAspect },
          })
        )
      } else {
        dispatch(
          setFilter({
            ...lotFilter,
            lotConfiguration: {
              ...lotConfigurations,
              aspects: [...lotAspect, value],
            },
          })
        )
      }
    }
  }

  const handleConfigurationOnChange = (
    configs: FilterOptionsConfigurations
  ) => {
    dispatch(setFilter({ ...lotFilter, configurations: configs }))
  }
  const handleLotConfigurationOnChange = (
    configs: FilterOptionLotConfigurations
  ) => {
    dispatch(setFilter({ ...lotFilter, lotConfiguration: configs }))
  }

  const handleShowAvailable = (checked: boolean) =>
    dispatch(setFilter({ ...lotFilter, showOnlyAvailable: checked }))

  const handleStageOnChange = (value: string) => {
    dispatch(
      setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
    )
    dispatch(
      setByFlag({ flag: HouseAndLandActiveKeyType.ActiveStageId, value })
    )
  }

  const handleAnyStage = (checked: boolean) => {
    dispatch(setFilter({ ...lotFilter, anyStage: checked }))
  }

  const apply = () => {
    toggle(false)
    dispatch(setFilter({ ...lotFilter, apply: true }))
    if (precinctLength > 1) {
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
      )
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePrecinctId,
          value: activePrecinct,
        })
      )
      if (stageOptions.length > 1) {
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActiveStageId,
            value: activeStage,
          })
        )
      }
      if (typeof onChangePrecinct === 'function') {
        onChangePrecinct(activePrecinct)
      }
    } else {
      handleStageOnChange(activeStage)
    }
  }

  const reset = () => {
    dispatch(resetFilter())
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      if (isOpen) {
        toggle(false)
      }
    }
  }

  React.useEffect(() => {
    if (isOpen && lotFilter.apply) {
      dispatch(setFilter({ ...lotFilter, apply: false }))
    }
  }, [lotFilter, activePrecinct, activeStage])

  React.useEffect(() => {
    setActivePrecinct(houseAndLand.activePrecinctId)
  }, [houseAndLand.activePrecinctId])

  React.useEffect(() => {
    if (isStageChange) {
      const firstStage = Object.keys(packageSummary?.stages ?? {})?.[0] || ''
      setActiveStage(firstStage)
    }
  }, [packageSummary, activePrecinct])

  React.useEffect(() => {
    setConfigurations(lotFilter.configurations)
    setLotConfigurations(lotFilter.lotConfiguration)
    setBuilder(lotFilter.builder)
    setStorey(lotFilter.storey)
    setShowAvailable(lotFilter.showOnlyAvailable)
    setAnyStage(lotFilter.anyStage)
  }, [lotFilter])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <IdleTimeHandler>
      <div
        ref={filterRef}
        className={`fixed top-[36px] z-30 h-page-container w-[297px] rounded-tl-2xl bg-white px-4 pb-10 text-black shadow-2xl ${
          isOpen ? '' : '-translate-x-full'
        } transition-transform duration-500`}
      >
        <div className="flex h-screen flex-col">
          <div className="mb-2 mt-6 flex items-center justify-between">
            <div>
              <button
                className="flex items-center"
                type="button"
                onClick={() => toggle(!isOpen)}
              >
                <CloseSvg
                  className="h-3.5 w-3.5 cursor-pointer"
                  strokeColor="black"
                />
              </button>
            </div>
            <div>
              <h4 className="text-xl font-semibold ">Filter</h4>
            </div>
            <div>
              <button
                type="button"
                onClick={() => reset()}
                className="text-base font-medium text-mainColour underline"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="no-scrollbar h-[84%] overflow-y-auto">
            <div className="mt-6 flex flex-col gap-[1.20rem] text-base font-medium">
              {precinctLength > 1 && (
                <div className="flex flex-col gap-1">
                  <span>Precinct</span>
                  <DropDown
                    onSelect={(value) => {
                      setActivePrecinct(value)
                      setStageChange(true)
                    }}
                    items={precinctOptions}
                    value={activePrecinct}
                    className="py-2"
                    buttonRadius="rounded-[7px]"
                    buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                    optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
                  />
                </div>
              )}

              {!hideStages && isFetchingStages && (
                <div className="flex flex-col gap-1">
                  <div className="h-4 w-16 rounded-lg bg-slate-400/20">
                    <div className="shimmer-container relative !p-2 opacity-50"></div>
                  </div>
                  <div className="h-10 w-full rounded-lg bg-slate-400/20">
                    <div className="shimmer-container relative opacity-50"></div>
                  </div>
                  <div className="mt-2 h-4 w-32 rounded-lg bg-slate-400/20">
                    <div className="shimmer-container relative !p-2 opacity-50"></div>
                  </div>
                </div>
              )}

              {stageOptions?.length > 1 && !hideStages && !isFetchingStages && (
                <div className="flex flex-col gap-1">
                  <span className={` ${checkAnyStage ? 'text-gray-300' : ''}`}>
                    Stage
                  </span>
                  <DropDown
                    onSelect={(value) => {
                      setActiveStage(value)
                    }}
                    items={stageOptions}
                    disabled={checkAnyStage}
                    value={activeStage}
                    className="py-2"
                    buttonRadius="rounded-[7px]"
                    buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                    optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
                  />

                  <div className="mt-2 inline-flex items-center">
                    <ToggleSwitch
                      key="anyLevel"
                      onChangeCallback={(e) => {
                        handleAnyStage(e.target.checked)
                      }}
                      initialState={checkAnyStage}
                      onColor="bg-mainColour"
                      offColor="bg-[#BDC3C7]"
                    />
                    <div className="ml-3 text-base font-medium">Any Stage</div>
                  </div>
                </div>
              )}

              {showPrice && lotConfigurations?.price && (
                <RangeOptions
                  field="price"
                  options={prices}
                  range={lotConfigurations.price}
                  handleChange={(configValue: MinMaxInterface) => {
                    handleLotConfigurationOnChange({
                      ...lotConfigurations,
                      price: configValue,
                    })
                  }}
                />
              )}

              {Object.entries(bedConfig)?.map(([field, value]) => {
                const range =
                  configurations[field as keyof FilterOptionsConfigurations]
                return (
                  range && (
                    <RangeOptions
                      field={field}
                      key={field}
                      options={value as unknown as Array<string | number>}
                      range={range}
                      handleChange={(configValue: MinMaxInterface) => {
                        handleConfigurationOnChange({
                          ...configurations,
                          [field as keyof FilterOptionsConfigurations]:
                            configValue,
                        })
                      }}
                    />
                  )
                )
              })}

              {lotFrontageOptions &&
                lotFrontageOptions?.length > 0 &&
                lotConfigurations.frontage && (
                  <RangeOptions
                    field="lotFrontage"
                    options={lotFrontageOptions}
                    range={lotConfigurations.frontage}
                    handleChange={(configValue: MinMaxInterface) => {
                      handleLotConfigurationOnChange({
                        ...lotConfigurations,
                        frontage: configValue,
                      })
                    }}
                  />
                )}

              {lotAreaOptions &&
                lotAreaOptions?.length > 0 &&
                lotConfigurations.area && (
                  <RangeOptions
                    field="lotArea"
                    options={lotAreaOptions}
                    range={lotConfigurations.area}
                    handleChange={(configValue: MinMaxInterface) => {
                      handleLotConfigurationOnChange({
                        ...lotConfigurations,
                        area: configValue,
                      })
                    }}
                  />
                )}

              {storeyOptions && storeyOptions?.length > 0 && (
                <div className="flex flex-col gap-1">
                  <span>Storey</span>
                  <div className="grid grid-cols-1 gap-2">
                    {storeyOptions?.map(({ label, value }) => (
                      <button
                        type="button"
                        key={label}
                        onClick={() => {
                          handleStoreyOnChange(value)
                        }}
                        className={`rounded py-2 text-center text-base font-medium ${
                          storey.includes(value)
                            ? 'bg-mainColour text-white'
                            : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                        }`}
                      >
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {aspects && aspects.length > 0 && lotConfigurations.aspects && (
                <div className="flex flex-col gap-1">
                  <span>Aspect</span>
                  <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(80px,1fr))]">
                    {aspects?.map((aspectValue: string) => (
                      <button
                        type="button"
                        key={aspectValue}
                        onClick={() => {
                          handleAspectOnChange(aspectValue.toLowerCase())
                        }}
                        className={`truncate rounded py-2 text-center text-base font-medium ${
                          lotConfigurations?.aspects?.includes(
                            aspectValue.toLowerCase()
                          )
                            ? 'bg-mainColour text-white'
                            : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                        }`}
                      >
                        {aspectValue}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {builderOptions && builderOptions?.length > 0 && (
                <div className="flex flex-col gap-1">
                  <span>Builder</span>
                  <div className="grid grid-cols-1 gap-2">
                    {builderOptions?.map((builderValue: string) => (
                      <button
                        type="button"
                        key={builderValue}
                        onClick={() => {
                          handleBuilderOnChange(builderValue.toLowerCase())
                        }}
                        className={`rounded py-2 text-center text-base font-medium ${
                          builder.includes(builderValue.toLowerCase())
                            ? 'bg-mainColour text-white'
                            : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                        }`}
                      >
                        {builderValue}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mb-12 flex flex-col gap-[1.20rem]">
            <div className="mt-2 inline-flex items-center whitespace-nowrap">
              <ToggleSwitch
                key="checkbox"
                onChangeCallback={(e) => {
                  handleShowAvailable(e.target.checked)
                }}
                initialState={showAvailable}
                onColor="bg-mainColour"
                offColor="bg-[#BDC3C7]"
              />
              <div className="ml-3 text-base font-medium">
                Only show available lots
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <button
                type="button"
                onClick={apply}
                disabled={lotFilter.apply}
                className={`rounded-lg px-4 py-4 text-xl font-semibold text-white duration-300  ${
                  lotFilter.apply ? 'bg-gray-300' : 'bg-mainColour'
                }`}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </IdleTimeHandler>
  )
}
export default connect(
  ({
    projectIdentity: { projectName },
    lotFilter,
    projectConfig: {
      prices,
      showPrice,
      bedConfig,
      aspects,
      houseAndLandConfig,
    },
    houseAndLand,
  }: RootStateType) => ({
    projectName,
    lotFilter,
    prices,
    showPrice,
    houseAndLand,
    houseAndLandConfig,
    bedConfig,
    aspects,
  })
)(HouseAndLandFilterPopup)

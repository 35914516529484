import React from 'react'

import { DataNotFound, ErrorHandler } from './errors'

export const UNINITIALIZED_STATUS = 'uninitialized'
export const PENDING_STATUS = 'pending'
export const FULFILLED_STATUS = 'fulfilled'
export const REJECTED_STATUS = 'rejected'

export interface Payload {
  isLoaded: boolean
  isError: boolean
  status: string
  data: any
  apiData?: any
  errorStatus?: number
}

export interface DataHandlerProps {
  children: React.ReactNode
  payload: Payload
  skeletonFrame?: React.ReactNode
  className?: string
  message?: string
}

function DataHandler({
  children,
  className,
  payload,
  skeletonFrame,
  message = 'This page needs to be setup',
}: DataHandlerProps): React.ReactElement {
  const checkData = <T,>(data: T) => {
    if (!data) {
      return false
    }
    if (Array.isArray(data)) {
      return data.length > 0
    }
    return true
  }

  const hasData = React.useMemo(
    () => checkData(payload.data) || checkData(payload.apiData),
    [payload]
  )

  const isLoaded = React.useMemo(() => {
    const { status, data } = payload

    if (
      (status === UNINITIALIZED_STATUS && checkData(data)) ||
      status === FULFILLED_STATUS ||
      status === REJECTED_STATUS
    ) {
      return true
    }

    return false
  }, [payload])

  if (!isLoaded) {
    if (skeletonFrame) {
      return <div className="h-full w-full">{skeletonFrame}</div>
    }

    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
      </div>
    )
  }

  if (!hasData) {
    if (payload.isError) {
      if (payload.errorStatus === 404) {
        return <DataNotFound message={message} />
      }
      return <ErrorHandler />
    }
    return <DataNotFound message={message} />
  }

  return <div className={`h-full w-full ${className ?? ''}`}>{children}</div>
}

export default DataHandler

import React from 'react'

import { SvgProps } from './types'

function StudySvg({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48'}
      height={height || '48'}
      fill="none"
      stroke={strokeColor || 'currentColor'}
      viewBox="0 0 18 17"
      strokeWidth={stroke || 1.4}
    >
      <g clipPath="url(#clip0_57_4625)">
        <path d="M3.5835 4.95831V12.9583M1.4585 4.95831H17.0418M3.5835 7.35831H14.9168M10.6668 10.5583H14.9168M14.9168 4.95831V12.9583M10.6668 4.95831V12.9583M12.7918 7.35831V8.15831M12.7918 10.5583V11.3583" />
      </g>
      <defs>
        <clipPath id="clip0_57_4625">
          <rect
            width={width || '48'}
            height={height || '48'}
            fill="none"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StudySvg

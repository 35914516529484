import React from 'react'
import { connect } from 'react-redux'

import type { RootStateTypeExtra, SessionMap } from '@store/types'

import FadeContainer from '@components/fade-container'
import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'
import Player from '@components/player'

import { VideoGalleryData } from '@api/video-gallery'

import handleMouseWheel from '@utilities/gallery-image-control-util'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

export interface CarouselContentProps {
  session: SessionMap | undefined
  slideContent: Array<VideoGalleryData>
  getCtrlVisibility: (arg: React.SetStateAction<boolean>) => void
  ctrlsHidden: boolean
}

function CarouselContent({
  session,
  slideContent,
  ctrlsHidden,
  getCtrlVisibility,
}: CarouselContentProps) {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const [toggleControls, setToggleControls] = React.useState(true)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const getDotItems = () =>
    slideContent.map((content: VideoGalleryData) => ({
      text: content.title,
    }))

  React.useEffect(() => {
    if (session) {
      const {
        videoGallery: {
          galleryControl: { activeItemID: activeItemIDFirebase },
        },
      } = session

      const activeItemIndexFirebase = slideContent.findIndex(
        (item) => item.id === activeItemIDFirebase
      )
      setCurrentSlide(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
    }
  }, [session, slideContent])

  return (
    <div
      onWheel={(e) => {
        if (!ctrlsHidden) {
          handleMouseWheel(
            e,
            'nextButton',
            'prevButton',
            currentSlide,
            slideContent.length
          )
        }
      }}
      className="relative h-full"
      data-testid="video-gallery"
    >
      {!ctrlsHidden && (
        <IdleTimeHandler>
          <FadeContainer className="relative z-10" show={toggleControls}>
            <div className="absolute flex pt-8">
              <SlideTrigger
                type="text"
                items={getDotItems()}
                setCurrentSlide={setCurrentSlide}
                currentSlide={currentSlide}
                dotContainerRef={dotContainerRef}
                toggleControls={toggleControls}
              />
            </div>
          </FadeContainer>
        </IdleTimeHandler>
      )}

      <GallerySlider onClick={() => setToggleControls((state) => !state)}>
        {slideContent.map((value, index) => (
          <GallerySlide
            index={index}
            key={value.title}
            className="overflow-hidden"
          >
            <div className="relative">
              <ImageHandler
                className="background-cover image-blur absolute inset-0 z-2"
                url={value.previewImageSrc}
                type="legacy"
              />

              <ImageHandler
                className="background-contain absolute inset-0 z-3"
                url={value.previewImageSrc}
                type="legacy"
                bgProps={{
                  gradiant: 0.5,
                }}
              >
                {index === currentSlide && (
                  <Player
                    galleryName="videoGallery"
                    getPlayerState={getCtrlVisibility}
                    videoUrl={AssetHandler({
                      url: value.src,
                      type: 'legacy',
                    })}
                    isActive={index === currentSlide}
                    playerControls={session?.videoGallery?.playerControl}
                    onPlayerPauseOrStop={() => setToggleControls(true)}
                    timeoutInSeconds={3}
                  />
                )}
              </ImageHandler>
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default connect(({ firestore: { session } }: RootStateTypeExtra) => ({
  session,
}))(CarouselContent)

import { ANY } from './constants/common'
import { isValueInRange } from './helper'
import { Configurations } from './types/common'
import {
  FilterOptionLotConfigurations,
  FilterOptions,
  FilterOptionsConfigurations,
  Lot,
  Package,
  PackageSummary,
  Stage,
  Stages,
} from './types/houseAndLand'

const isConfigMatch = (
  config: Configurations | undefined,
  filterConfig: FilterOptionsConfigurations | undefined
): boolean => {
  if (!config || !filterConfig) {
    return true
  }

  const configFields: Array<keyof FilterOptionsConfigurations> = [
    'bed',
    'study',
    'bath',
    'powderRoom',
    'car',
  ]

  return configFields.every((field) => {
    const configValue = filterConfig[field]

    if (!configValue) {
      return true
    }

    const pkgValue = Number(config?.[field] ?? 0)
    return isValueInRange(configValue, pkgValue)
  })
}

const isBuilderIncluded = (
  pkgBuilder: string | undefined,
  filterBuilderList: Array<string> | undefined
): boolean => {
  if (!pkgBuilder || !filterBuilderList?.length) {
    return true
  }

  return filterBuilderList.some(
    (b) => b.toLowerCase() === pkgBuilder.toLowerCase()
  )
}

const isStoreyIncluded = (
  pkgStorey: number | undefined,
  filterStoreyList: Array<number> | undefined
): boolean => {
  if (pkgStorey === undefined || !filterStoreyList?.length) {
    return true
  }

  return filterStoreyList.indexOf(pkgStorey) !== -1
}

const filterPackages = (
  packages: Array<Package>,
  filterOptions: FilterOptions
): Package[] => {
  const { configurations, builder, storey } = filterOptions

  return packages.filter((pkg) => {
    const matchesConfiguration = isConfigMatch(
      pkg.configuration,
      configurations
    )
    const matchesBuilder = isBuilderIncluded(pkg.builder, builder)
    const matchesStorey = isStoreyIncluded(pkg.storey, storey)

    return matchesConfiguration && matchesBuilder && matchesStorey
  })
}

const isLotConfigMatch = (
  lot: Lot,
  filterLotConfig: FilterOptionLotConfigurations | undefined
): boolean => {
  const { frontage, area, aspects, price } = filterLotConfig || {}

  const matchesLotFrontage =
    frontage && lot?.frontage ? isValueInRange(frontage, lot.frontage) : true

  const matchesLotArea =
    area && lot.area ? isValueInRange(area, Number(lot.area)) : true

  const matchAspect =
    aspects && aspects.length && lot?.aspect
      ? aspects.some((a) => a.toLocaleLowerCase() === lot.aspect.toLowerCase())
      : true

  const matchesPrice =
    price && lot?.price ? isValueInRange(price, lot.price) : true

  return matchesLotFrontage && matchesLotArea && matchAspect && matchesPrice
}

const filterLots = (
  lots: Array<Lot>,
  filterOptions: FilterOptions,
  availableStatusLabel: string,
  filteredLotIds?: Array<string>
): Array<Lot> =>
  lots
    .filter(
      (lot: Lot) => !filteredLotIds || filteredLotIds.indexOf(lot.id) !== -1
    )
    .filter(
      (lot) =>
        !filterOptions.showOnlyAvailable || lot.status === availableStatusLabel
    )
    .filter((lot) => isLotConfigMatch(lot, filterOptions.lotConfiguration))

const getUniqueLotIds = (filteredPackages: Array<Package>): Array<string> =>
  filteredPackages
    .flatMap((pkg: Package) => pkg.lots)
    .filter(
      (lotId: string, index: number, lots: Array<string>) =>
        lots.indexOf(lotId) === index
    )

const filterLotsWithinStages = (
  stages: Stages,
  filterOptions: FilterOptions,
  availableStatusLabel: string,
  filteredLotIds?: Array<string>
): Stages =>
  Object.entries(stages).reduce(
    (accumulator: Stages, [stageKey, stageValue]: [string, Stage]) => {
      const filteredLots = filterLots(
        stageValue.lots,
        filterOptions,
        availableStatusLabel,
        filteredLotIds
      )

      if (filteredLots.length > 0) {
        accumulator[stageKey] = {
          ...stageValue,
          lots: filteredLots,
        }
      }

      return accumulator
    },
    {} as Stages
  )

const isDefaultOption = (
  value: string | number | Array<string | number> | FilterOptionsConfigurations
): boolean => {
  if (Array.isArray(value)) {
    return value.every(isDefaultOption)
  }
  if (typeof value === 'object' && value !== null) {
    if ('min' in value && 'max' in value) {
      return value.min === ANY && value.max === ANY
    }
    return Object.values(value).every(isDefaultOption)
  }
  return false
}

const hasPackageFilterOptionsSelected = (
  filterOptions: FilterOptions
): boolean => {
  const { configurations = {}, storey = [], builder = [] } = filterOptions

  return (
    !Object.values(configurations).every(isDefaultOption) ||
    !isDefaultOption(storey) ||
    !isDefaultOption(builder)
  )
}

const filterPackageSummary = (
  packageSummary: PackageSummary,
  filterOptions: FilterOptions,
  availableStatusLabel: string
): PackageSummary | null => {
  let { packages, stages } = packageSummary

  const hasPackageFilterOption = hasPackageFilterOptionsSelected(filterOptions)

  if (hasPackageFilterOption) {
    packages = filterPackages(packages, filterOptions)

    if (!packages.length) {
      return null
    }

    const filteredLotIds: Array<string> = getUniqueLotIds(packages)

    stages = filterLotsWithinStages(
      stages,
      filterOptions,
      availableStatusLabel,
      filteredLotIds
    )
  } else {
    stages = filterLotsWithinStages(stages, filterOptions, availableStatusLabel)
  }

  return { packages, stages }
}

export default filterPackageSummary

import React from 'react'

import { SvgProps } from './types'

function CloseMarkSvg({
  width,
  height,
  className,
  size,
  styles,
  fill,
  stroke,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.6,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '31'}
      height={height || '30'}
      viewBox="0 0 31 30"
      fill={fill || '#fff'}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={stroke || 1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}

export default CloseMarkSvg

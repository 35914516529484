import React from 'react'

import { LoadingSvg } from '@src/components/adgroup-svg/react'

import PhoneInput from '@components/phone-input/phone-input'

interface AddAgentFormProps {
  onCancel: () => void
  onConfirm: (values: Record<string, string>) => void
  isProcessing?: boolean
  errors?: string[]
}

const initialFormValue = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
}

function AddAgentForm({
  onCancel,
  onConfirm,
  isProcessing,
  errors,
}: AddAgentFormProps) {
  const [formValue, setFormValue] = React.useState(initialFormValue)
  const [phoneInputValidity, setPhoneInputValidity] = React.useState(false)
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false)

  const handleInput: (
    index: keyof typeof initialFormValue
  ) => React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (index) => (e) => {
      setFormValue((prevVal) => ({
        ...prevVal,
        [index]: e.target.value,
      }))
    },
    []
  )

  const handlePhoneInput = React.useCallback((value: string) => {
    setFormValue((prevVal) => ({
      ...prevVal,
      phone: value,
    }))
  }, [])

  const handleSubmit: React.FormEventHandler = React.useCallback(
    (e) => {
      e.preventDefault()
      if (!phoneInputValidity) {
        return
      }
      onConfirm(formValue)
    },
    [onConfirm, formValue, phoneInputValidity]
  )

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="text-left">
          <h4 className="text-sm font-semibold">AGENT NAME</h4>
          <div className="flex gap-3">
            <input
              className="text-default my-2 w-full rounded border-gray-200 px-6 py-2 focus:border-mainColour focus:ring-0"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formValue.firstName}
              onChange={handleInput('firstName')}
              required
            />
            <input
              className="text-default my-2 w-full rounded border-gray-200 px-6 py-2 focus:border-mainColour focus:ring-0"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formValue.lastName}
              onChange={handleInput('lastName')}
              required
            />
          </div>
        </div>
        <div className="text-left">
          <h4 className="text-sm font-semibold">AGENT PHONE</h4>
          <PhoneInput
            className="my-2"
            value={formValue.phone}
            onChange={handlePhoneInput}
            onValidityChange={(status) => setPhoneInputValidity(status)}
            showInputError={hasClickedSubmit}
            required
          />
        </div>
        <div className="text-left">
          <h4 className="text-sm font-semibold">AGENT EMAIL</h4>
          <input
            className="text-default my-2 w-full rounded border-gray-200 px-6 py-2 focus:border-mainColour focus:ring-0"
            type="email"
            name="email"
            placeholder="agent.doe@gmail.com"
            value={formValue.email}
            onChange={handleInput('email')}
            required
          />
        </div>
      </div>
      {errors?.length && (
        <div>
          <ul className="list-disc text-sm text-rose-800">
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex justify-between gap-4">
        <button
          type="button"
          className="p-2 font-medium underline"
          onClick={onCancel}
          disabled={isProcessing}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`shadow-standard rounded px-4 py-2 font-medium text-white ${
            isProcessing ? 'bg-blue-400' : 'bg-blue-500'
          }`}
          disabled={isProcessing}
          onClick={() => setHasClickedSubmit(true)}
        >
          <div className="flex items-center gap-2">
            {isProcessing && <LoadingSvg className="h-5 w-5" />}
            <span>Confirm</span>
          </div>
        </button>
      </div>
    </form>
  )
}

export default AddAgentForm

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { setFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import { setStages } from '@store/actionSlices/interactivePlan'
import {
  GalleryControlInterface,
  InteractivePlanType,
  PlayerControlInterface,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import DataHandler from '@components/data-handler'
import { GalleryProvider } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import {
  PannellumDataInterface,
  PanoramaType,
} from '@components/showcase-pannellum/types'

import {
  FloorPlateGalleryData,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  MappingStageCollection,
  selectFromResult as selectStageFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  LotInterface,
  PrecinctListItemInterface,
} from '@api/types/house-and-land-type'

import { getTabKeyByIndexBy } from '@utilities/floor-tab'

import StageCanvas from './stage-canvas'
import StageCarouselContent from './stage-carousel-content'
import StageGalleryTab from './stage-gallery-tab'
import StageSkeleton from './stage-skeleton'

interface ComponentProps {
  originalLots: Array<LotInterface>
  filteredLots: Array<LotInterface>
  getVideoPlayerState: (arg: boolean) => void
  projectName: string
  session: SessionMap | undefined
  floorPlateGallery: Array<FloorPlateGalleryData>
  activePrecinctId: string
  activeStageId: string
  precinctList: Array<PrecinctListItemInterface>
  stagePlan: MappingStageCollection
  panoramic: Array<PannellumDataInterface>
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  controls: {
    duration: number
    active: boolean
  }
}

function StageView({
  originalLots,
  filteredLots,
  getVideoPlayerState,
  projectName,
  session,
  floorPlateGallery,
  activePrecinctId,
  activeStageId,
  precinctList,
  stagePlan,
  panoramic,
  fullScreenToggle,
  setFullScreenToggle,
  controls,
}: ComponentProps) {
  const dispatch = useDispatch()
  const [isConnected, setIsConnected] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: controls.duration,
    isIdleActive: controls.active,
  })

  const [galleryControl, setGalleryControl] =
    React.useState<GalleryControlInterface>()
  const [playerControl, setPlayerControl] =
    React.useState<PlayerControlInterface>()
  const [activeTab, setActiveTab] = React.useState('floor')
  const [videoPlayerState, setVideoPlayerState] = React.useState(false)

  const stagePlanMapKey = React.useMemo(
    () =>
      precinctList.length > 1
        ? `${activePrecinctId}-${activeStageId}`
        : activeStageId,
    [precinctList, activePrecinctId, activeStageId]
  )

  const hasStageData: boolean = React.useMemo(
    () => stagePlan[stagePlanMapKey] !== undefined,
    [stagePlan, stagePlanMapKey]
  )

  const hasPanoramicData: boolean = React.useMemo(() => {
    const foundItem = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === PanoramaType.Stage &&
        (item.panoramaGroup || '')?.toString() === activeStageId?.toString()
    )
    return foundItem !== undefined
  }, [panoramic, activeStageId])

  const floorPlateGalleryAssets = React.useMemo(() => {
    if (floorPlateGallery.length === 0 || !activeStageId) {
      return []
    }

    if (activePrecinctId && activeStageId) {
      return (
        floorPlateGallery.find(
          (value) =>
            value.precinct === activePrecinctId && value.stage === activeStageId
        )?.assets || []
      )
    }

    return (
      floorPlateGallery.find((value) => value.stage === activeStageId)
        ?.assets || []
    )
  }, [floorPlateGallery, activePrecinctId, activeStageId])

  const stagePayload = useGetInteractivePlanQuery(
    { projectName, type: InteractivePlanType.Stages, slug: stagePlanMapKey },
    { selectFromResult: selectStageFromResult, skip: hasStageData }
  )

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName },
    { selectFromResult: selectFromFloorPlateGalleryResult }
  )

  React.useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload
    if (floorPlateGallery.length === 0 && floorPlateGalleryData.length > 0) {
      dispatch(setFloorPlateGallery(floorPlateGalleryData))
    }
  }, [floorPlateGalleryPayload])

  React.useEffect(() => {
    const { maps } = stagePayload
    if (Object.entries(maps?.stages || {}).length > 0 && !hasStageData) {
      dispatch(setStages(maps.stages))
    }
  }, [stagePayload])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        floorGallery: {
          galleryControl: galleryControlFirebase,
          playerControl: playerControlFirebase,
        },
      } = session
      setIsConnected(connected)
      setGalleryControl(galleryControlFirebase)
      setPlayerControl(playerControlFirebase)
      setActiveTab(
        getTabKeyByIndexBy(galleryControlFirebase?.activeTabIndex || 0)
      )
    }
  }, [session?.connected, session?.floorGallery])

  return (
    <DataHandler
      payload={{
        ...stagePayload,
        data: Object.entries(stagePlan[stagePlanMapKey] || {}),
      }}
      skeletonFrame={<StageSkeleton />}
    >
      <>
        {activeTab === 'gallery' && floorPlateGalleryAssets.length > 0 ? (
          <GalleryProvider
            galleryName="floorGallery"
            naturalSlideWidth={150}
            naturalSlideHeight={125}
            dragEnabled={false}
            disableKeyboard
            className="relative h-full w-full"
            originalSlides={floorPlateGalleryAssets}
            galleryControl={galleryControl}
            totalSlides={floorPlateGalleryAssets.length}
            skipInfinite
          >
            {(slideContent) => (
              <StageCarouselContent
                slideContent={slideContent}
                getVideoPlayerState={(arg: boolean) => {
                  getVideoPlayerState(arg)
                  setVideoPlayerState(arg)
                }}
                isRemoteAppConnected={isConnected}
                playerControls={playerControl}
              />
            )}
          </GalleryProvider>
        ) : (
          <StageCanvas
            stagePlan={stagePlan[stagePlanMapKey]}
            originalLots={originalLots}
            filteredLots={filteredLots}
            session={session}
            isFullWidth={isAppIdle}
            fullScreenToggle={fullScreenToggle}
            setFullScreenToggle={setFullScreenToggle}
          />
        )}

        {(floorPlateGalleryAssets.length > 0 || hasPanoramicData) &&
          !videoPlayerState &&
          !isConnected && (
            <IdleTimeHandler>
              <div className="absolute bottom-5 right-5 z-3">
                <div className="flex items-center">
                  {floorPlateGalleryAssets.length > 0 && (
                    <StageGalleryTab
                      activeTab={activeTab}
                      handleClick={(arg: string) => setActiveTab(arg)}
                    />
                  )}
                  {hasPanoramicData && (
                    <Link
                      to="panoramic"
                      className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                    >
                      Panoramic
                    </Link>
                  )}
                </div>
              </div>
            </IdleTimeHandler>
          )}
      </>
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    firestore: { session },
    projectConfig,
    floorPlateGallery,
    houseAndLand: { activePrecinctId, activeStageId, precinctList },
    interactivePlan: { stages },
    panoramic,
  }: RootStateTypeExtra) => ({
    projectName,
    session,
    floorPlateGallery,
    panoramic,
    activePrecinctId,
    activeStageId,
    precinctList,
    stagePlan: stages,
    controls: projectConfig.controls,
  })
)(StageView)

import React from 'react'

interface SkeletonProps {
  children?: React.ReactNode
  containerClass?: string
}
function Skeleton({ children, containerClass = '' }: SkeletonProps) {
  return (
    <div className={`${containerClass} h-screen w-screen bg-mainColour`}>
      {children}
      <div className="shimmer-container absolute left-0 top-0 z-1 h-full w-full"></div>
    </div>
  )
}

export default Skeleton

import React from 'react'
import { Link } from 'react-router-dom'

import {
  CloseSvg,
  EyeSlashSvg,
  EyeSvg,
  FullScreenSvg,
  LinkSvg,
  PanoramaSvg,
  PlaySvg,
  StopSvg,
} from '@svg/react'

interface ComponentProps {
  hasPanoramicData: boolean
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  unitInfoToggle: boolean
  setUnitInfoToggle: (arg: boolean) => void
  slideShowToggle: boolean
  setSlideShowToggle: (arg: boolean) => void
  externalLinkToggle: boolean
  showExternalLinks: boolean
  setShowExternalLinks: (arg: boolean) => void
  isVideoPlaying: boolean
  shouldShowSlideshowBtn: boolean
}

function TopControlBar({
  hasPanoramicData,
  fullScreenToggle,
  setFullScreenToggle,
  unitInfoToggle,
  setUnitInfoToggle,
  slideShowToggle,
  setSlideShowToggle,
  externalLinkToggle,
  showExternalLinks,
  setShowExternalLinks,
  isVideoPlaying,
  shouldShowSlideshowBtn,
}: ComponentProps) {
  return (
    <div className="flex items-center justify-between text-xs font-semibold tracking-widest text-white">
      <button
        type="button"
        onClick={() => setFullScreenToggle(!fullScreenToggle)}
        className="rounded bg-[#747474] p-2"
      >
        {fullScreenToggle ? (
          <CloseSvg className="h-6 w-6 text-white" />
        ) : (
          <FullScreenSvg className="h-6 w-6 text-white" />
        )}
      </button>
      <div className="flex gap-4">
        {!isVideoPlaying && (
          <>
            {externalLinkToggle && (
              <button
                type="button"
                onClick={() => setShowExternalLinks(!showExternalLinks)}
                className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
              >
                <LinkSvg className="mr-1 h-4 w-4" />
                {showExternalLinks
                  ? 'Hide External Link'
                  : 'Show External Link'}
              </button>
            )}

            {!slideShowToggle && (
              <button
                type="button"
                onClick={() => setUnitInfoToggle(!unitInfoToggle)}
                className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
              >
                {unitInfoToggle ? (
                  <>
                    <EyeSlashSvg className="mr-1 h-4 w-4" /> Hide Info
                  </>
                ) : (
                  <>
                    <EyeSvg className="mr-1 h-4 w-4" /> Show Info
                  </>
                )}
              </button>
            )}
            {hasPanoramicData && !slideShowToggle && (
              <Link
                to="panoramic"
                className="mr-4 inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
              >
                <PanoramaSvg className="mr-1 h-5 w-5" /> Panoramic View
              </Link>
            )}
            {shouldShowSlideshowBtn && (
              <button
                type="button"
                className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
                onClick={() => setSlideShowToggle(!slideShowToggle)}
              >
                {!slideShowToggle ? (
                  <>
                    <PlaySvg className="mr-1 h-3 w-3" /> Start Slideshow
                  </>
                ) : (
                  <>
                    <StopSvg className="mr-1 h-5 w-5" /> Stop Slideshow
                  </>
                )}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default TopControlBar

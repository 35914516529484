import React from 'react'

interface AbsoluteContainerProps {
  children: React.ReactNode
}

function AbsoluteContainer({ children }: AbsoluteContainerProps) {
  return (
    <div className="absolute h-full w-full bg-neutralColour">{children}</div>
  )
}

export default AbsoluteContainer

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { selectHouseAndLand, setByFlag } from '@store/actionSlices/houseAndLand'
import { initialFilter } from '@store/actionSlices/lotFilter'
import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotFilterInterface,
  LotShortlistInterface,
  RootStateTypeExtra,
} from '@store/types'

import {
  HouseAndLandActiveKeyType,
  PackageInterface,
  StageInterface,
} from '@api/types/house-and-land-type'

import countFilterChanges from '@utilities/lot-filter-util'

import ActionsButton from './actions-button'
import AddFilterButton from './add-filter-button'
import BackButton from './back-button'
import LotCard from './lot-card'
import { LotCardProps } from './lot-card/lot-card'
import LotSidePanelSkeleton from './lot-side-panel-skeleton'
import NoLotCard from './no-lot-card'
import StageHead from './stage-head'

interface LotsSidePanelProps {
  isLoaded: boolean
  stages: Array<StageInterface>
  packages: Array<PackageInterface>
  filterPopupToggle: boolean
  setFilterPopupToggle: (arg: boolean) => void
  activeStageId: string
  activeLotId: string
  lotFilter: LotFilterInterface
  shortlists: ShortlistState['shortlists']
  onLotConfigurationValueChange?: LotCardProps['onConfigurationValueChange']
  onLotConfigurationOptionSelect?: LotCardProps['onConfigurationOptionSelect']
  onLotConfigurationPropertyExpand?: LotCardProps['onConfigurationPropertyExpand']
}

const PADDING_FROM_CONTAINER_TOP = 124

function LotsSidePanel({
  isLoaded,
  stages,
  packages,
  filterPopupToggle,
  setFilterPopupToggle,
  activeStageId,
  activeLotId,
  lotFilter,
  shortlists,
  onLotConfigurationValueChange,
  onLotConfigurationOptionSelect,
  onLotConfigurationPropertyExpand,
}: LotsSidePanelProps) {
  const dispatch = useDispatch()
  const { activePackageId } = useSelector(selectHouseAndLand)
  const initialActivePackageIdRef = React.useRef<string | null>(activePackageId)
  const lotSidePanelContainerRef = React.useRef<HTMLDivElement>(null)
  const handleInitialActivePackageIdRef = React.useRef(true)

  const packagesOfLot = React.useCallback(
    (lotId: string) =>
      packages.filter((item: PackageInterface) => item.lots.includes(lotId)),
    [packages]
  )

  const filteredCount = React.useMemo(() => {
    if (lotFilter.apply) {
      return countFilterChanges(initialFilter, lotFilter)
    }
    return 0
  }, [lotFilter.apply])

  const { defaultPackageId, validInitialActivePackageId } =
    React.useMemo(() => {
      const lotPackages = packagesOfLot(activeLotId)
      return {
        defaultPackageId: lotPackages?.[0]?.id,
        validInitialActivePackageId: lotPackages?.find(
          (item) => item.id === initialActivePackageIdRef.current
        )?.id,
      }
    }, [activeLotId, packagesOfLot])

  const { isShortlisted, shortlistedConfiguration } = React.useMemo(() => {
    const matchedShortlist = shortlists.find(
      (item) => item.propertyId === activeLotId
    ) as LotShortlistInterface | undefined
    return {
      isShortlisted: !!matchedShortlist,
      shortlistedConfiguration: matchedShortlist?.configuration,
    }
  }, [shortlists, activeLotId])

  React.useEffect(() => {
    if (!activeLotId) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePackageId,
          value: '',
        })
      )
      return
    }
    if (
      initialActivePackageIdRef.current &&
      handleInitialActivePackageIdRef.current &&
      validInitialActivePackageId
    ) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePackageId,
          value: validInitialActivePackageId,
        })
      )
      handleInitialActivePackageIdRef.current = false
      return
    }
    dispatch(
      setByFlag({
        flag: HouseAndLandActiveKeyType.ActivePackageId,
        value:
          (isShortlisted
            ? shortlistedConfiguration?.floorplan
            : defaultPackageId) || '',
      })
    )
  }, [activeLotId, defaultPackageId, validInitialActivePackageId])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeStageElement = document.querySelector(
        '.active-stage'
      ) as HTMLDivElement

      if (activeStageElement && lotSidePanelContainerRef?.current) {
        const offsetTop =
          activeStageElement.offsetTop -
          lotSidePanelContainerRef.current.offsetTop

        lotSidePanelContainerRef.current.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [activeStageId, lotSidePanelContainerRef.current])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeLotElement = document.getElementById(activeLotId)
      if (activeLotElement && lotSidePanelContainerRef?.current) {
        lotSidePanelContainerRef.current.scrollTo({
          top: activeLotElement.offsetTop - PADDING_FROM_CONTAINER_TOP,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [activeLotId, lotSidePanelContainerRef.current])

  return (
    <div className="h-full w-full bg-[#EEEEEE]">
      <div className="flex justify-between bg-white p-4">
        <div className="flex items-center space-x-4">
          <BackButton activeLotId={activeLotId} activeStageId={activeStageId} />
          <ActionsButton disable={!isLoaded} />
        </div>
        <AddFilterButton
          disable={!isLoaded}
          filterCount={filteredCount}
          popupState={filterPopupToggle}
          togglePopup={setFilterPopupToggle}
        />
      </div>
      {!isLoaded ? (
        <LotSidePanelSkeleton />
      ) : (
        <div
          ref={lotSidePanelContainerRef}
          className="house-and-land-scrollbar h-unit-sidepanel-container overflow-y-scroll px-4 pb-0.5"
        >
          {stages.length > 0 ? (
            stages.map((stage) => (
              <div
                key={stage.id}
                className={`${
                  stage.label === activeStageId ? 'active-stage' : ''
                }`}
              >
                <StageHead
                  stage={stage}
                  active={stage.label === activeStageId}
                />
                <div className="flex flex-col space-y-3">
                  {stage.lots.length > 0 ? (
                    stage.lots.map((lot) => (
                      <LotCard
                        key={lot.id}
                        lot={lot}
                        stageInfo={stage}
                        active={activeLotId === lot.id}
                        packages={packagesOfLot(lot.id)}
                        onConfigurationValueChange={
                          activeLotId === lot.id
                            ? onLotConfigurationValueChange
                            : undefined
                        }
                        onConfigurationOptionSelect={
                          activeLotId === lot.id
                            ? onLotConfigurationOptionSelect
                            : undefined
                        }
                        onConfigurationPropertyExpand={
                          activeLotId === lot.id
                            ? onLotConfigurationPropertyExpand
                            : undefined
                        }
                      />
                    ))
                  ) : (
                    <NoLotCard />
                  )}
                </div>
              </div>
            ))
          ) : (
            <NoLotCard />
          )}
        </div>
      )}
    </div>
  )
}

export default connect(
  ({
    houseAndLand: { activeStageId, activeLotId },
    projectConfig: { showPrice },
    lotFilter,
    shortlist: { shortlists },
  }: RootStateTypeExtra) => ({
    lotFilter,
    activeStageId,
    activeLotId,
    showPrice,
    shortlists,
  })
)(LotsSidePanel)

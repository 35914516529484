import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateTypeExtra, SessionMap } from '@store/types'

import { hasToken } from '@utilities/helper'
import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

import { ArrowPathSvg } from '@svg/react'

interface ErrorHandlerProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
}

function ErrorHandler({ session, projectIdentity, token }: ErrorHandlerProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })

  const history = useHistory()
  const [isConnected, setIsConnected] = useState(false)

  const [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger] =
    useMatrixCacheCleaner({
      projectName: projectIdentity.projectName,
      email: token.email,
    })

  const resetClearCacheTriggerKeyFromFirebaseAndReload = async () => {
    if (session && session.connected) {
      await firebaseControlQuery.update({
        [`clearCacheTriggerKey`]: '',
        [`clearCacheKeyState`]: 'cleared',
      })
    }
    window.location.reload()
  }

  const handleRefreshClick = () => {
    handleMatrixCacheCleanerTrigger(true)
  }

  useEffect(() => {
    if (isMatrixCacheCleaned) {
      history.push(`/${projectIdentity.projectName}/vision`)

      resetClearCacheTriggerKeyFromFirebaseAndReload().catch((err) =>
        console.error(err)
      )
    }
  }, [isMatrixCacheCleaned])

  useEffect(() => {
    if (hasToken(token) || !session || !session.connected) {
      return
    }

    const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session
    handleMatrixCacheCleanerTrigger(firebaseClearCacheTriggerKey !== '')
  }, [session?.connected, session?.clearCacheTriggerKey])

  useEffect(() => {
    if (!session) {
      return
    }

    const { connected } = session
    setIsConnected(connected)
  }, [session])

  return (
    <div className="h-screen">
      <div className="flex h-full">
        <div className="flex w-1/2 items-center justify-center bg-mainColour">
          <ArrowPathSvg className="h-32 w-32 text-white" />
        </div>
        <div className="flex w-1/2 items-center bg-white">
          <div className="mx-8">
            <h3 className="text-4xl font-bold">New updates available!</h3>
            {isConnected ? (
              <p className="mt-5 text-xl">
                {`It looks like this page hasn't loaded correctly, possibly due
                  to recent updates. Please navigate to tools and click "Clear
                  Cache" button within the Remote.`}
              </p>
            ) : (
              <p className="mt-5 text-xl">
                {`It looks like this page hasn't loaded correctly, possibly due
                  to recent updates. Please go back to the main page or use the
                  menu to explore other sections. Alternatively, click below to
                  refresh.`}
              </p>
            )}
            {!isConnected && (
              <button
                type="button"
                className="mt-5 rounded-md bg-mainColour px-8 py-4 text-[18px] font-semibold leading-none text-white"
                onClick={handleRefreshClick}
              >
                Refresh Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({ firestore: { session }, projectIdentity, token }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
  })
)(ErrorHandler)

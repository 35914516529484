import React from 'react'

interface ProjectListExpandSkeletonProps {
  length: number
}
function SkeletonCard() {
  return (
    <div className="shimmer-container relative h-full w-full rounded-none opacity-50">
      <div className="flex h-full w-full items-center justify-between">
        <div className="h-6 w-40 rounded-full bg-zinc-300"></div>
        <div className="h-6 w-10 rounded-full bg-zinc-300"></div>
      </div>
    </div>
  )
}

function ProjectListCollapseSkeleton() {
  return (
    <div className="flex h-16 w-full items-center rounded border-2 border-gray-300 bg-slate-50">
      <SkeletonCard />
    </div>
  )
}

function ProjectListExpandSkeleton({
  length = 1,
}: ProjectListExpandSkeletonProps) {
  const content = [...Array(length)].map((_, index) => (
    <div
      key={`item-${index.toString()}`}
      className="flex h-16 w-full items-center border-b bg-slate-50"
    >
      <SkeletonCard />
    </div>
  ))
  return <div>{content}</div>
}
export { ProjectListCollapseSkeleton, ProjectListExpandSkeleton }

import React from 'react'

function VideoSkeleton() {
  return (
    <div className="relative flex h-screen w-full flex-col justify-between overflow-hidden bg-secondaryColour">
      <div className="m-8 flex flex-col gap-4">
        <div className="skeleton h-6 w-[260px]"></div>
        <div className="skeleton h-6 w-[220px]"></div>
        <div className="skeleton h-6 w-[260px]"></div>
        <div className="skeleton h-6 w-[220px]"></div>
      </div>
    </div>
  )
}

export default VideoSkeleton

import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ShortlistDocument {
  config?: string
  contentType: string
  driver: string
  fileName: string
  id: string
  location: string
  meta: ShortlistDocumentMeta
  name: string
  position: number
  subType?: string
  title?: string
  updated: string
}

export interface ShortlistDocumentMeta {
  logo: boolean
  map: boolean
  cover: boolean
}

interface GetShortlistDocumentsParams {
  projectName: string
}

interface RegisterBuyerParams {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
}

interface RegisterBuyerResponse {
  _id: string
  email: string
}

export interface SaveShortlistParams {
  project: string
  documentIds?: string[]
  agentId?: string
  shortlistedProperties: {
    propertyId: string
    archived: boolean
    configuration?: PackageConfiguration
  }[]
  user: string
}

export interface SaveShortlistResponse {
  id: string
  propertyId: string
  propertyName: string
}

export interface PackageConfiguration {
  packageId?: string
  floorPlanId?: string
  facadeId?: string
  facadeColorId?: string
  internalThemeId?: string
  internalColorSchemeId?: string
}

export const shortlistApi = createApi({
  reducerPath: 'shortlistedApi',
  baseQuery: baseQueryInterceptor,
  tagTypes: ['AgentsList'],
  endpoints: (builder) => ({
    getShortlistDocuments: builder.query<
      ApiResponse<Array<ShortlistDocument>>,
      GetShortlistDocumentsParams
    >({
      query: (params: GetShortlistDocumentsParams) => ({
        url: `/v1/${params.projectName}/shortlist/documents`,
        method: 'get',
      }),
    }),
    registerBuyer: builder.mutation<
      ApiResponse<RegisterBuyerResponse>,
      RegisterBuyerParams
    >({
      query: (params: RegisterBuyerParams) => ({
        url: `/v1/user`,
        method: 'post',
        body: {
          firstName: params.firstName,
          lastName: params.lastName,
          phone_number: params.phoneNumber,
          email: params.email,
        },
      }),
    }),
    saveShortlist: builder.mutation<
      ApiResponse<SaveShortlistResponse>,
      SaveShortlistParams
    >({
      query: (params: SaveShortlistParams) => ({
        url: `/v3/shortlists`,
        method: 'post',
        body: {
          project: params.project,
          shortlistedProperties: params.shortlistedProperties,
          documents: params.documentIds || [],
          agentId: params.agentId || null,
          user: params.user,
        },
      }),
    }),
  }),
})

export const selectFromShortlistDocumentsResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  data: Array<ShortlistDocument>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const {
  useGetShortlistDocumentsQuery,
  useRegisterBuyerMutation,
  useSaveShortlistMutation,
} = shortlistApi

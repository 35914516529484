import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { setByFlag } from '@store/actionSlices/houseAndLand'

import { HouseAndLandActiveKeyType } from '@api/types/house-and-land-type'

import { ArrowSvg } from '@svg/react'

interface ComponentProps {
  activeLotId: string
  activeStageId: string
}

function BackButton({ activeLotId, activeStageId }: ComponentProps) {
  const dispatch = useDispatch()
  const history = useHistory()

  const PRECINCT_VIEW = 'precinct'

  const handleBackNav = () => {
    if (activeLotId) {
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
      )
      return
    }
    if (activeStageId) {
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveStageId, value: '' })
      )
      history.push(PRECINCT_VIEW)
    }
  }

  return (
    <button
      className="rounded-full border-2 border-zinc-400 p-1"
      type="button"
      onClick={() => handleBackNav()}
    >
      <ArrowSvg className="h-5 w-5 stroke-zinc-400" rotate="left" />
    </button>
  )
}

export default BackButton

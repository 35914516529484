import { bucket, bucketLegacy } from '../../../config/aws'
import {
  getActiveProjectCanonicalName,
  getAssetHost,
  getAssetPort,
} from './helper'

const DEFAULT_HOST = 'localhost'

export interface AssetHandlerProps {
  url: string
  type?: 'legacy' | 'new'
  noSpliceUrl?: boolean
  staticUrl?: boolean
}

export default function AssetHandler({
  url,
  type,
  noSpliceUrl,
  staticUrl = false,
}: AssetHandlerProps) {
  const host = getAssetHost()
  const port = getAssetPort()
  const activeProject = getActiveProjectCanonicalName()

  let routeTrail = ''
  let fileName = ''
  let actionedUrl = ''

  const uriEncodeFileName = (file: string) => {
    if (decodeURIComponent(file) !== file) {
      return file
    }
    return encodeURIComponent(file)
  }

  const sanitizeUrl = (source: string) => {
    const splitSource = source
      .split('?')[0]
      .split('/')
      .filter((res) => res)
    const urlArray = noSpliceUrl
      ? splitSource
      : splitSource.filter((res) => res !== 'apid').slice(2, splitSource.length)
    fileName = `/${uriEncodeFileName(urlArray[urlArray.length - 1])
      ?.replace(/[(]/g, '%28')
      ?.replace(/[)]/g, '%29')}`
    if (urlArray.length > 1) {
      routeTrail = `/${urlArray.slice(0, urlArray.length - 1).join('/')}`
    }
  }

  const handleAssets = () => {
    const urlParms = url.split('?').length > 1 ? `?${url.split('?')[1]}` : ''

    sanitizeUrl(url)
    const source = type === 'legacy' ? bucketLegacy : bucket
    if (port) {
      actionedUrl = `http://${host || DEFAULT_HOST}:${port?.replace(
        /"/g,
        ''
      )}/${activeProject}${fileName}`
      return
    }
    if (fileName && routeTrail) {
      actionedUrl = `${source}${routeTrail}${fileName}${urlParms}`
      return
    }
    actionedUrl = `${source}${fileName}${urlParms}`
  }

  if (url) {
    if (staticUrl === true) {
      actionedUrl = url
    } else {
      handleAssets()
    }
  }

  return actionedUrl
}

import React from 'react'

import { SvgProps } from './types'

function ArrowPathSvg({ width, height, className, styles, stroke }: SvgProps) {
  return (
    <svg
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48px'}
      height={height || '48px'}
      viewBox="0 0 24 24"
      strokeWidth={stroke || 1.5}
      fill="none"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  )
}

export default ArrowPathSvg

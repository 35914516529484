import React from 'react'
import { Link } from 'react-router-dom'

import {
  CloseSvg,
  EyeSlashSvg,
  EyeSvg,
  FullScreenSvg,
  PanoramaSvg,
  PlaySvg,
  StopSvg,
} from '@svg/react'

interface ComponentProps {
  hasPanoramicData: boolean
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  packageInfoToggle: boolean
  setPackageInfoToggle: (arg: boolean) => void
  slideShowToggle: boolean
  setSlideShowToggle: (arg: boolean) => void
  isVideoPlaying: boolean
  shouldShowSlideshowBtn: boolean
}

function Controls({
  hasPanoramicData,
  fullScreenToggle,
  setFullScreenToggle,
  packageInfoToggle,
  setPackageInfoToggle,
  slideShowToggle,
  setSlideShowToggle,
  isVideoPlaying,
  shouldShowSlideshowBtn,
}: ComponentProps) {
  return (
    <div
      className={`flex flex-col items-end space-y-4 ${
        isVideoPlaying && 'hidden'
      }`}
    >
      {!slideShowToggle && (
        <>
          <button
            type="button"
            onClick={() => setFullScreenToggle(!fullScreenToggle)}
            className="flex items-center space-x-2"
          >
            <span
              className={`lot-view-control-text ${
                !packageInfoToggle ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {fullScreenToggle && 'Exit'} Fullscreen
            </span>
            <span className="lot-view-control-icon">
              {fullScreenToggle ? (
                <CloseSvg className="h-5 w-5" />
              ) : (
                <FullScreenSvg className="h-5 w-5" />
              )}
            </span>
          </button>
          <button
            type="button"
            onClick={() => setPackageInfoToggle(!packageInfoToggle)}
            className="flex items-center space-x-2"
          >
            <span
              className={`lot-view-control-text ${
                !packageInfoToggle ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {packageInfoToggle ? 'Hide' : 'Show'} Info
            </span>
            <span className="lot-view-control-icon">
              {packageInfoToggle ? (
                <EyeSlashSvg className="h-5 w-5" />
              ) : (
                <EyeSvg className="h-5 w-5" />
              )}
            </span>
          </button>
          {hasPanoramicData && (
            <Link to="panoramic" className="flex items-center space-x-2">
              <span
                className={`lot-view-control-text ${
                  !packageInfoToggle ? 'opacity-0' : 'opacity-100'
                }`}
              >
                Panoramic View
              </span>
              <span className="lot-view-control-icon">
                <PanoramaSvg className="h-5 w-5" />
              </span>
            </Link>
          )}
        </>
      )}
      {shouldShowSlideshowBtn && (
        <button
          type="button"
          onClick={() => setSlideShowToggle(!slideShowToggle)}
          className="flex items-center space-x-2"
        >
          <span
            className={`lot-view-control-text ${
              !packageInfoToggle ? 'opacity-0' : 'opacity-100'
            }`}
          >
            {!slideShowToggle ? 'Start' : 'Stop'} Slideshow
          </span>
          <span className="lot-view-control-icon">
            {!slideShowToggle ? (
              <PlaySvg className="h-3 w-3" />
            ) : (
              <StopSvg className="h-4 w-4" />
            )}
          </span>
        </button>
      )}
    </div>
  )
}

export default Controls

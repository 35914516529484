import React from 'react'
import { useDispatch } from 'react-redux'

import {
  setFirebaseIp,
  setFirebasePort,
  setOfflineMode,
} from '@store/actionSlices/appConfig'

import { GenericModalCard as ModalCard } from '@components/modals'
import ToggleSwitch from '@components/toggle-switch'

import { validateIp, validatePort } from '@utilities/helper'
import ResetUtil from '@utilities/reset-util'

import { ChevronSvg } from '@svg/react'

interface OfflineModeHandlerProps {
  offlineMode: boolean
  firebasePort: string
  firebaseIp: string
}

function OfflineModeHandler({
  offlineMode,
  firebaseIp,
  firebasePort,
}: OfflineModeHandlerProps) {
  const dispatch = useDispatch()
  const resetUtil = ResetUtil()

  const [toggledModal, setToggleModal] = React.useState(false)

  const [offlineToggleSwitch, setOfflineToggleSwitch] =
    React.useState(offlineMode)

  const [firebaseOfflineToggleSwitch, setFirebaseOfflineToggleSwitch] =
    React.useState(!!(firebaseIp && firebasePort))

  const [port, setPort] = React.useState({
    value: firebasePort,
    valid: false,
  })

  const [ip, setIp] = React.useState({
    value: firebaseIp,
    valid: false,
  })

  const saveOfflineSettings = () => {
    dispatch(setOfflineMode(offlineToggleSwitch))
    dispatch(setFirebasePort(firebaseOfflineToggleSwitch ? port.value : ''))
    dispatch(
      setFirebaseIp(firebaseOfflineToggleSwitch ? ip.value : 'localhost')
    )

    if (!offlineToggleSwitch) {
      resetUtil.resetLocalStorage()
    }
    window.location.reload()
  }

  const setModalState = () => {
    setOfflineMode(offlineMode)
    if (firebaseIp && firebasePort) {
      setFirebaseOfflineToggleSwitch(true)
      return
    }
    setFirebaseOfflineToggleSwitch(false)
  }

  const validateFormItems = () => {
    if (firebaseOfflineToggleSwitch) {
      setIp({ ...ip, valid: validateIp(ip.value) })
      setPort({ ...port, valid: validatePort(port.value) })
      return
    }
    setIp({ value: 'localhost', valid: true })
    setPort({ value: '', valid: true })
  }

  React.useEffect(() => {
    setModalState()
    validateFormItems()
  }, [toggledModal])

  React.useEffect(() => {
    validateFormItems()
  }, [firebaseOfflineToggleSwitch])

  return (
    <>
      <ModalCard
        headerLabel="Offline Settings"
        toggle={(status: boolean) => setToggleModal(status)}
        isVisible={toggledModal}
        okAction={() => saveOfflineSettings()}
        okLabel="Save"
        cancelAction={() => setModalState()}
        disableOk={!port.valid || !ip.valid}
      >
        <div className="px-3 py-2">
          <div className="flex items-center rounded border border-gray-200 px-4 py-2">
            <span className="mr-auto">Offline Data:</span>
            <ToggleSwitch
              key="offlineToggleSwitch"
              onChangeCallback={() =>
                setOfflineToggleSwitch(!offlineToggleSwitch)
              }
              initialState={offlineToggleSwitch}
            />
          </div>
          <div className="py-2">
            <span className="ml-2 text-sm">
              {offlineMode
                ? 'You are using the app in offline mode.'
                : "Cache this remote's data to use offline (excluding media)."}
            </span>
          </div>
          <div className="items-center rounded border border-gray-200 px-4 py-2">
            <div className="flex">
              <span className="mr-auto">Offline Remote:</span>
              <ToggleSwitch
                key="firebaseOfflineToggleSwitch"
                onChangeCallback={() =>
                  setFirebaseOfflineToggleSwitch(!firebaseOfflineToggleSwitch)
                }
                initialState={firebaseOfflineToggleSwitch}
              />
            </div>
            <div
              className={`pt-2 ${firebaseOfflineToggleSwitch ? '' : 'hidden'}`}
            >
              <input
                type="text"
                name="firebaseIp"
                value={ip.value}
                className="mb-2 w-full rounded-md border-2 border-gray-300 focus:border-gray-300 focus:ring-0"
                onChange={(event) =>
                  setIp({
                    value: event.target.value,
                    valid: validateIp(event.target.value),
                  })
                }
                placeholder="Host IP"
              />
              <input
                type="text"
                name="firebasePort"
                value={port.value}
                className="w-full rounded-md border-2 border-gray-300 focus:border-gray-300 focus:ring-0"
                onChange={(event) =>
                  setPort({
                    value: event.target.value,
                    valid: validatePort(event.target.value),
                  })
                }
                placeholder="Port"
              />
              <div>
                {!ip.valid && ip.value && (
                  <small className="text-red-500">Invalid Ip format. </small>
                )}
                {!port.valid && port.value && (
                  <small className="text-red-500">Invalid port format.</small>
                )}
              </div>
            </div>
          </div>
          <div className="py-2">
            <span className="text-sm">
              {firebasePort && ip
                ? `You're hosting a remote session localy`
                : 'Connect to your local Remote network'}
            </span>
          </div>
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggledModal)}
        type="button"
        className="flex w-full items-center rounded border border-2 border-gray-300 p-3 px-5 text-left"
      >
        <span className="text-sm">Offline Mode</span>
        <ChevronSvg
          className="ml-auto h-5 w-5 py-1"
          stroke={4}
          rotate="right"
        />
      </button>
    </>
  )
}

export default OfflineModeHandler

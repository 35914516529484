import React from 'react'

import { SvgProps } from './types'

function EyeSvg({ width, height, className, size, styles }: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }
  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '18'}
      height={height || '15'}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.48119 11.3934L1.38731 13.4872L2.625 14.7245L4.94594 12.4036C6.06681 13.0462 7.34475 13.4872 8.75 13.4872C14 13.4872 17.5 7.36225 17.5 7.36225C17.5 7.36225 16.2081 5.10519 14.0188 3.33113L16.1127 1.23725L14.875 0L12.5541 2.32094C11.4332 1.67825 10.1552 1.23725 8.75 1.23725C3.5 1.23725 0 7.36225 0 7.36225C0 7.36225 1.29194 9.61931 3.48119 11.3934ZM5.25 7.36225C5.25 5.42937 6.81712 3.86225 8.75 3.86225C9.39881 3.86225 10.0043 4.04206 10.5254 4.34963L9.19756 5.67744C9.05363 5.63938 8.90575 5.61225 8.75 5.61225C7.78487 5.61225 7 6.39712 7 7.36225C7 7.518 7.02712 7.66588 7.06519 7.80981L5.73738 9.13763C5.42981 8.61656 5.25 8.01106 5.25 7.36225ZM12.25 7.36225C12.25 9.29513 10.6829 10.8622 8.75 10.8622C8.10119 10.8622 7.49569 10.6824 6.97462 10.3749L8.30244 9.04706C8.44637 9.08512 8.59425 9.11225 8.75 9.11225C9.71513 9.11225 10.5 8.32737 10.5 7.36225C10.5 7.2065 10.4729 7.05862 10.4348 6.91469L11.7626 5.58687C12.0702 6.10794 12.25 6.71344 12.25 7.36225Z"
        fill="white"
      />
    </svg>
  )
}
export default EyeSvg

import React from 'react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { setBackground } from '@adUtilities/asset-handler/asset-util'

const FALLBACK_IMAGE_URL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL || ''

export interface ComponentInterface {
  url: string
  className: string
  type?: 'legacy' | 'new'
  noSpliceUrl?: boolean
  bgProps?: {
    gradiant: number
  }
  alt?: string
  isImage?: boolean
  showFallbackImage?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
}

function ImageHandler({
  url,
  type,
  noSpliceUrl,
  className,
  bgProps,
  children,
  style,
  isImage,
  showFallbackImage = true,
  alt = '',
}: ComponentInterface) {
  const isMounted = React.useRef(true)
  const [bgImg, setBgImg] = React.useState('none')
  const imageRef = React.useRef<HTMLImageElement>(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  const assetUrl = React.useMemo(
    () =>
      url
        ? AssetHandler({
            url,
            type,
            noSpliceUrl,
          })
        : '',
    [url, type]
  )

  React.useEffect(() => {
    const img = new Image()
    img.src = assetUrl
    img.onload = () => {
      if (isMounted.current) {
        const { backgroundImage } = setBackground(assetUrl, bgProps)
        setBgImg(backgroundImage)
        setIsLoaded(true)
      }
    }
    img.onerror = () => {
      if (isMounted.current && showFallbackImage) {
        if (imageRef.current) {
          imageRef.current.src = FALLBACK_IMAGE_URL
        }
        const { backgroundImage } = setBackground(assetUrl, {
          ...bgProps,
        })
        setBgImg(`${backgroundImage}, url(${FALLBACK_IMAGE_URL})`)
      }
      setIsLoaded(true)
    }
  }, [assetUrl])

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  if (isImage) {
    return <img ref={imageRef} className={className} alt={alt} src={assetUrl} />
  }

  return (
    <div
      className={`${className} transition-opacity duration-500 ${
        isLoaded ? 'opacity-100' : 'opacity-0'
      }`}
      style={{
        backgroundImage: bgImg,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default ImageHandler

import React from 'react'
import { useLocation } from 'react-router'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { Channel, ProjectIdentity } from '@store/types'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
}

const ROUTES = ['level-view', 'building']

const LightChannelResetter = ({
  projectIdentity,
  channels,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    lightKey: projectIdentity.lightMap,
    sessionKeys: projectIdentity.sessionId,
  })

  const location = useLocation()

  const resetLightMap = React.useCallback(() => {
    if (!channels || channels.length < 1) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => ({
      ...channel,
      status: false,
    }))
    firebaseControlQuery.updateLightChannel(updatedChannels)
    firebaseControlQuery.updateFilterStateInLightMap(false)
  }, [channels])

  React.useEffect(() => {
    const paths = location.pathname.split('/')
    const activeRoute = paths[2] || ''
    if (!ROUTES.includes(activeRoute)) {
      resetLightMap()
    }
  }, [location])
}

export default LightChannelResetter

import React from 'react'

interface FadeContainerProps {
  show: boolean
  children: React.ReactNode
  className?: string
}

function FadeContainer({ show, children, className }: FadeContainerProps) {
  return (
    <div
      className={`transition-opacity ${
        show ? 'opacity-100' : 'pointer-events-none opacity-0'
      } ${className}`}
    >
      {children}
    </div>
  )
}

export default FadeContainer

import React from 'react'

import { SvgProps } from './types'

function FrontageSvg({ width, height, className, styles, stroke }: SvgProps) {
  return (
    <svg
      className={className}
      style={styles}
      width={width || '18'}
      height={height || '18'}
      viewBox={`0 0 ${width || 18} ${height || 18}`}
      stroke={stroke ? String(stroke) : 'currentColor'}
      fill="red"
    >
      <path
        d="M14.6665 2.84088L14.6736 2.83301"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8335 2.84088L11.8406 2.83301"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 2.84088L9.00708 2.83301"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1665 2.84088L6.17359 2.83301"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 2.84088L3.34058 2.83301"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 5.67364L3.34034 5.66577"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 8.50713L3.34034 8.49927"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 5.67364L14.6738 5.66577"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 8.50713L14.6738 8.49927"
        stroke="#666666"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6675 14.1663L3.33691 14.1663M14.6675 14.1663L12.8966 15.583M14.6675 14.1663L12.8966 12.7497M3.33691 14.1663L4.75358 15.583M3.33691 14.1663L4.75358 12.7497"
        stroke="#666666"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FrontageSvg

import { deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import React from 'react'

import { SessionMap } from '@store/types'

import firestore from './firebase-db'
import type { Channel, SessionKey } from './types'

interface WildCardObjectInterface {
  [key: string]: any
}

interface FirebaseControlQueryProps {
  projectName: string
  sessionKeys?: Array<SessionKey>
  lightKey?: string
}

interface CreateSessionInterface {
  projectName: string
  sessionId: string
}

export const getCurrentSessionKey = ({
  sessionKeys,
  projectName,
}: {
  sessionKeys: Array<SessionKey>
  projectName: string
}) =>
  sessionKeys.find((res: SessionKey) => res.project === projectName)?.key || ''

const FirebaseControlQuery = ({
  projectName,
  sessionKeys = [],
  lightKey = '',
}: FirebaseControlQueryProps) => {
  const [sessionKey, setCurrentSessionKey] = React.useState('')

  const doesProjectExist = async (projectName: string) => {
    if (!firestore) {
      return false
    }
    const docRef = doc(firestore, 'devsuite', projectName)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
  }

  const isProjectEnabled = async (projectName: string) => {
    if (!firestore) {
      return false
    }
    const docRef = doc(firestore, 'devsuite', projectName)
    const docSnap = await getDoc(docRef)
    const exists = docSnap.exists()

    if (!exists) {
      return false
    }

    const data = docSnap.data()

    if (!data) {
      return true
    }

    if (!('enable' in data)) {
      return true
    }

    return data?.enable ?? false
  }

  const getMap = async () => {
    if (!firestore) {
      return undefined
    }
    const docRef = doc(firestore, 'session-base', 'map')
    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) {
      return undefined
    }
    return docSnap.data() as SessionMap
  }

  const doesLightMapExist = async ({
    projectName,
    lightId,
  }: {
    projectName: string
    lightId: string
  }) => {
    if (!firestore) {
      return false
    }
    const docRef = doc(firestore, 'devsuite', projectName, 'light-map', lightId)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
  }

  const doesSessionExist = async ({
    projectName,
    sessionId,
  }: {
    projectName: string
    sessionId: string
  }) => {
    if (!firestore) {
      return false
    }
    const docRef = doc(firestore, 'devsuite', projectName, 'session', sessionId)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
  }

  const createSession = async ({
    projectName,
    sessionId,
  }: CreateSessionInterface) => {
    if (!firestore) {
      return false
    }
    const exist = await doesSessionExist({ projectName, sessionId })
    const map = await getMap()
    if (exist || !map) {
      return false
    }
    await setDoc(
      doc(firestore, 'devsuite', projectName, 'session', sessionId),
      map
    ).catch(() => console.error('createSession encountered an error'))
    return true
  }

  const updateConnection = async (connected: boolean) => {
    if (!firestore) {
      return
    }
    if (sessionKey) {
      const exist = await doesSessionExist({
        projectName,
        sessionId: sessionKey,
      })
      if (exist) {
        await updateDoc(
          doc(firestore, 'devsuite', projectName, 'session', sessionKey),
          {
            connected,
          }
        ).catch(() => console.error('updateConnection encountered an error'))
      }
    }
  }

  const refreshSession = async () => {
    if (!firestore) {
      return
    }
    const map = await getMap()

    if (!sessionKey) {
      return
    }

    const exist = await doesSessionExist({ projectName, sessionId: sessionKey })
    if (exist && map) {
      updateDoc(
        doc(firestore, 'devsuite', projectName, 'session', sessionKey),
        {
          ...map,
          connected: true,
        }
      ).catch(() => console.error('refreshSession encountered an error'))
    }
  }

  const deleteSession = async () => {
    if (!firestore) {
      return
    }
    if (!sessionKey) {
      return
    }
    await deleteDoc(
      doc(firestore, 'devsuite', projectName, 'session', sessionKey)
    ).catch(() => console.error('deleteSession encountered an error'))
  }

  const update = async (obj: WildCardObjectInterface) => {
    if (!firestore) {
      return
    }
    if (!sessionKey) {
      return
    }
    const exist = await doesSessionExist({ projectName, sessionId: sessionKey })
    if (exist) {
      await updateDoc(
        doc(firestore, 'devsuite', projectName, 'session', sessionKey),
        obj
      ).catch(() => console.error('update encountered an error'))
    }
  }

  const updateLightChannel = async (obj: WildCardObjectInterface) => {
    if (!firestore) {
      return
    }
    if (!lightKey) {
      return
    }
    const exist = await doesLightMapExist({
      projectName,
      lightId: lightKey as string,
    })
    if (exist && lightKey) {
      await updateDoc(
        doc(firestore, 'devsuite', projectName, 'light-map', lightKey),
        {
          channels: obj,
        }
      ).catch(() => console.error('updateLightChannel encountered an error'))
    }
  }

  const updateLightMap = async (channels: Array<Channel>) => {
    if (!firestore) {
      return
    }
    const exist = await doesLightMapExist({
      projectName,
      lightId: lightKey as string,
    })
    if (exist && lightKey) {
      updateDoc(
        doc(firestore, 'devsuite', projectName, 'light-map', lightKey),
        {
          channels,
        }
      ).catch(() => console.error('updateLightMap encountered an error'))
    }
  }

  const updateFilterStateInLightMap = async (filteredApplied: boolean) => {
    if (!firestore) {
      return
    }
    if (!lightKey) {
      return
    }
    const exist = await doesLightMapExist({
      projectName,
      lightId: lightKey as string,
    })
    if (exist && lightKey) {
      updateDoc(
        doc(firestore, 'devsuite', projectName, 'light-map', lightKey),
        {
          hasFilter: filteredApplied,
        }
      ).catch(() =>
        console.error('updateFilterStateInLightMap encountered an error')
      )
    }
  }

  const updateShortlist = async (value: {
    key: string
    payload: Record<string, any>
  }) => {
    if (!firestore) {
      return
    }
    const docRef = doc(firestore, 'shortlisted-property', value.key)
    const docSnap = await getDoc(docRef)
    const exists = docSnap.exists()

    if (!exists) {
      await setDoc(doc(firestore, 'shortlisted-property', value.key), {
        payload: JSON.stringify(value.payload),
        createdAt: Date.now(),
      }).catch(() => console.error('updateShortlist encountered an error'))
    } else {
      await updateDoc(doc(firestore, 'shortlisted-property', value.key), {
        payload: JSON.stringify(value.payload),
        createdAt: Date.now(),
      }).catch(() => console.error('updateShortlist an error'))
    }
  }

  React.useEffect(() => {
    const currentSessionKey = getCurrentSessionKey({
      sessionKeys,
      projectName,
    })
    if (currentSessionKey) {
      setCurrentSessionKey(currentSessionKey)
    }
  }, [sessionKeys])

  return {
    update,
    getMap,
    deleteSession,
    createSession,
    refreshSession,
    updateLightMap,
    updateShortlist,
    updateConnection,
    isProjectEnabled,
    doesProjectExist,
    updateLightChannel,
    updateFilterStateInLightMap,
  }
}

export default FirebaseControlQuery

import React from 'react'

import { SvgProps } from './types'

function StarSvg({
  width,
  height,
  className,
  fill,
  stroke,
  strokeColor,
  styles,
}: SvgProps) {
  return (
    <svg
      className={className}
      style={{
        ...styles,
      }}
      width={width || '30'}
      height={height || '28'}
      viewBox="0 0 30 28"
      fill={fill || 'currentColor'}
    >
      <path
        d="M10.4492 8.9813L13.9132 2.0053C14.0141 1.80341 14.1692 1.6336 14.3611 1.51492C14.553 1.39624 14.7742 1.33337 14.9999 1.33337C15.2256 1.33337 15.4468 1.39624 15.6387 1.51492C15.8307 1.6336 15.9857 1.80341 16.0866 2.0053L19.5506 8.9813L27.2946 10.1066C27.5179 10.1376 27.7281 10.2308 27.9011 10.3754C28.074 10.5201 28.2028 10.7105 28.2728 10.9249C28.3427 11.1393 28.3509 11.369 28.2966 11.5879C28.2422 11.8068 28.1274 12.0059 27.9652 12.1626L22.3626 17.5893L23.6852 25.256C23.8546 26.24 22.8146 26.9893 21.9252 26.5253L14.9999 22.904L8.07324 26.5253C7.18524 26.9906 6.14524 26.24 6.31458 25.2546L7.63724 17.588L2.03458 12.1613C1.87323 12.0045 1.75911 11.8055 1.7052 11.587C1.65128 11.3686 1.65974 11.1394 1.7296 10.9255C1.79947 10.7116 1.92793 10.5216 2.1004 10.3771C2.27287 10.2326 2.48243 10.1393 2.70524 10.108L10.4492 8.9813Z"
        stroke={strokeColor}
        strokeWidth={stroke || 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StarSvg

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { setFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import {
  GalleryControlInterface,
  PlayerControlInterface,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import { GalleryProvider } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import {
  FloorPlateGalleryData,
  GalleryData,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  MappingBlockCollection,
  MappingCollection,
} from '@api/interactive-plan'

import { getTabKeyByIndexBy } from '@utilities/floor-tab'

import FloorCarouselContent from './floor-carousel-content'
import FloorGalleryTab from './floor-gallery-tab'
import FloorplanCanvas from './floorplan-canvas'

interface FloorPlateProps {
  getVideoPlayerState: (arg: boolean) => void
  projectName: string
  session: SessionMap | undefined
  floorPlateGallery: Array<FloorPlateGalleryData>
  activeLevel: string
  activeBlock: string
  floorPlan: MappingCollection
  blocks: MappingBlockCollection
  panoramic: Array<PannellumDataInterface>
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  controls: {
    active: boolean
    duration: number
  }
}

function FloorplateView({
  getVideoPlayerState,
  projectName,
  session,
  floorPlateGallery,
  activeLevel,
  activeBlock,
  floorPlan,
  blocks,
  panoramic,
  fullScreenToggle,
  setFullScreenToggle,
  controls,
}: FloorPlateProps) {
  const dispatch = useDispatch()

  const blockKeys: Array<string> = Object.keys(blocks) || []

  const [skipFloorPlateGalleryApi, setSkipFloorPlateGalleryApi] =
    React.useState(true)

  const [isConnected, setIsConnected] = React.useState(false)
  const [galleryControl, setGalleryControl] =
    React.useState<GalleryControlInterface>()
  const [playerControl, setPlayerControl] =
    React.useState<PlayerControlInterface>()
  const [activeTab, setActiveTab] = React.useState('floor')
  const [videoPlayerState, setVideoPlayerState] = React.useState(false)
  const [hasPanoramicData, setPanoramicDataState] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: controls.duration,
    isIdleActive: controls.active,
  })

  const [floorPlateGalleryAssets, setFloorPlateGalleryAssets] = React.useState<
    Array<GalleryData>
  >([])

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName },
    {
      selectFromResult: selectFromFloorPlateGalleryResult,
      skip: skipFloorPlateGalleryApi,
    }
  )

  const hasInteractiveMap = (): boolean => {
    let mapName = activeLevel

    if (blockKeys.length > 1) {
      mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel
    }

    if (mapName in floorPlan) {
      return floorPlan[mapName]?.image !== ''
    }
    return false
  }

  React.useEffect(() => {
    if (floorPlateGallery.length === 0 && skipFloorPlateGalleryApi) {
      setSkipFloorPlateGalleryApi(false)
    }
  }, [floorPlateGallery])

  React.useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload
    if (floorPlateGallery.length === 0 && floorPlateGalleryData.length > 0) {
      dispatch(setFloorPlateGallery(floorPlateGalleryData))
    }
  }, [floorPlateGalleryPayload])

  React.useEffect(() => {
    if (floorPlateGallery.length > 0) {
      let foundData = null

      if (activeBlock && activeLevel) {
        foundData = floorPlateGallery.find(
          (value) =>
            value.block.toString() === activeBlock &&
            value.level.toString() === activeLevel
        )
      } else {
        foundData = floorPlateGallery.find(
          (value) => value.level.toString() === activeLevel
        )
      }

      if (foundData !== null) {
        setFloorPlateGalleryAssets(foundData?.assets ?? [])
      }
    }
    setActiveTab('floor')
  }, [activeLevel, activeBlock, floorPlateGallery])

  React.useEffect(() => {
    const foundItem = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === 'level' &&
        (item.panoramaGroup || '')?.toString() === activeLevel?.toString()
    )
    setPanoramicDataState(foundItem !== undefined)
  }, [panoramic, activeLevel])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        floorGallery: {
          galleryControl: galleryControlFirebase,
          playerControl: playerControlFirebase,
        },
      } = session
      setIsConnected(connected)
      setGalleryControl(galleryControlFirebase)
      setPlayerControl(playerControlFirebase)
      setActiveTab(
        getTabKeyByIndexBy(galleryControlFirebase?.activeTabIndex || 0)
      )
    }
  }, [session])

  if (!hasInteractiveMap())
    return (
      <div className="flex h-full w-full items-center justify-center bg-white text-4xl">
        No interactive floor plate found for this level.
      </div>
    )

  return (
    <>
      {activeTab === 'gallery' && floorPlateGalleryAssets.length > 0 ? (
        <GalleryProvider
          galleryName="floorGallery"
          naturalSlideWidth={150}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className="relative h-full w-full"
          originalSlides={floorPlateGalleryAssets}
          galleryControl={galleryControl}
          totalSlides={floorPlateGalleryAssets.length}
          skipInfinite
        >
          {(slideContent) => (
            <FloorCarouselContent
              slideContent={slideContent}
              getVideoPlayerState={(arg: boolean) => {
                getVideoPlayerState(arg)
                setVideoPlayerState(arg)
              }}
              isRemoteAppConnected={isConnected}
              playerControls={playerControl}
            />
          )}
        </GalleryProvider>
      ) : (
        <FloorplanCanvas
          session={session}
          isFullWidth={isAppIdle}
          fullScreenToggle={fullScreenToggle}
          setFullScreenToggle={setFullScreenToggle}
        />
      )}

      {(floorPlateGalleryAssets.length > 0 || hasPanoramicData) &&
        !videoPlayerState &&
        !isConnected && (
          <IdleTimeHandler>
            <div className="absolute bottom-5 right-5 z-3">
              <div className="flex items-center">
                {floorPlateGalleryAssets.length > 0 && (
                  <FloorGalleryTab
                    activeTab={activeTab}
                    handleClick={(arg: string) => setActiveTab(arg)}
                  />
                )}
                {hasPanoramicData && (
                  <Link
                    to="panoramic"
                    className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                  >
                    Panoramic
                  </Link>
                )}
              </div>
            </div>
          </IdleTimeHandler>
        )}
    </>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    projectConfig: { controls },
    firestore: { session },
    floorPlateGallery,
    building: { activeLevel, activeBlock },
    interactivePlan: { floorplan: floorPlan, blocks },
    panoramic,
  }: RootStateTypeExtra) => ({
    projectName,
    session,
    floorPlateGallery,
    activeLevel,
    activeBlock,
    floorPlan,
    blocks,
    panoramic,
    controls,
  })
)(FloorplateView)

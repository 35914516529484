import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setInteractiveMap } from '@store/actionSlices/interactiveMap'
import { ConfigInterface } from '@store/actionSlices/projectConfig'
import { RootStateTypeExtra } from '@store/types'

import { Layer, Stack } from '@api/interactive-map'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

import { ArrowSvg, EyeCloseSvg, EyeSvg } from '@svg/react'

import LayerApartment from './layer-apartment'
import LayerHnl from './layer-hnl'

interface LayerListProps {
  children: React.ReactNode
  activeCategory: string
  toggleCategoryDetails: (toggle: boolean) => void
  interactiveMap: Stack
  setCategoryBackground: (arg: string) => void
  setLayerBackground: (arg: string) => void
  projectConfig: ConfigInterface
}

interface LayerExtended extends Layer {
  category: string
  label: string
  controlSrc: string
  baseImageOverride: string
}

function LayerList({
  activeCategory,
  toggleCategoryDetails,
  children,
  interactiveMap,
  setCategoryBackground,
  setLayerBackground,
  projectConfig,
}: LayerListProps) {
  const dispatch = useDispatch()
  const layersByCategory = interactiveMap.layers.filter(
    (layer) => layer.category === activeCategory
  )
  const handleAnimationLayer = (layer: LayerExtended, visible: boolean) => ({
    ...layer,
    src: `${layer.src.split('?')[0]}?${Math.random()}`,
    animation: layer?.animation
      ? `${layer.animation.split('?')[0]}?${Math.random()}`
      : '',
    visible,
  })

  const setRelevantBackground = (layers: Layer[], layer: Layer) => {
    let canSetLayerBackground = false
    layers.forEach((lyr) => {
      if (lyr.groupId === layer.groupId) {
        if (lyr.visible && lyr.baseImageOverride) {
          canSetLayerBackground = true
        }
      }
    })
    if (canSetLayerBackground) {
      setCategoryBackground('')
      setLayerBackground(layer.baseImageOverride)
      return
    }
    const visibleLayer = layers.find((lyr) => lyr.visible)
    const activeCategoryDefaultLayer = layers.find(
      (lyr) => lyr.category === activeCategory && lyr.categoryDefault
    )
    if (!visibleLayer) {
      setCategoryBackground(activeCategoryDefaultLayer?.baseImageOverride || '')
    }
    setLayerBackground(visibleLayer?.baseImageOverride || interactiveMap.src)
  }

  const handleInteractiveMap = (layer: Layer) => {
    const layers: Array<Layer> = interactiveMap.layers.map((layerItem) => {
      if (interactiveMap.storyMode && layerItem.category !== layer.category) {
        return { ...layerItem, visible: false }
      }
      if (layerItem.groupId === layer.groupId) {
        if (layerItem.type.toLowerCase() === 'animation') {
          return handleAnimationLayer(layerItem, !layer.visible)
        }
        return { ...layerItem, visible: !layer.visible }
      }
      return layerItem
    })
    setRelevantBackground(layers, layer)
    dispatch(
      setInteractiveMap({
        ...interactiveMap,
        layers,
      })
    )
  }
  return (
    <div className="relative rounded-xl bg-white/60 px-6 py-4">
      {children}
      <div className="flex items-center gap-2">
        <button
          type="button"
          onClick={() => {
            toggleCategoryDetails(false)
          }}
        >
          <ArrowSvg className="h-6 w-6" strokeColor="#000" rotate="left" />
        </button>
        <span className="text-sm font-semibold">{activeCategory}</span>
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {layersByCategory.map((layer) => (
          <div
            key={layer.groupId}
            className={`flex flex-col rounded-md bg-white px-4 py-2 transition-all duration-300 ${
              layer.visible ? 'border-l-4 border-mainColour ' : ''
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex">
                <span className="text-sm font-bold">
                  {projectConfig.type === ProjectType.Apartment ? (
                    <LayerApartment
                      label={layer.label}
                      actionRoute={layer.actionRoute}
                    />
                  ) : (
                    <LayerHnl
                      label={layer.label}
                      actionRoute={layer.actionRoute}
                    />
                  )}
                </span>
              </div>
              <button
                type="button"
                className={`rounded-full ${
                  layer.visible ? 'bg-mainColour' : 'bg-gray-300'
                } px-1.5 py-2 transition-all duration-300`}
                onClick={() => handleInteractiveMap(layer)}
              >
                {layer.visible ? (
                  <EyeCloseSvg size="s" className="h-4 w-5" />
                ) : (
                  <EyeSvg size="s" className="h-4 w-5" />
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default connect(({ projectConfig }: RootStateTypeExtra) => ({
  projectConfig,
}))(LayerList)

import React from 'react'
import { connect } from 'react-redux'

import { ConfigInterface } from '@store/actionSlices/projectConfig'
import { RootStateTypeExtra } from '@store/types'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

import Apartment from './apartment'
import HouseAndLand from './house-and-land'

interface PanoramicViewProps {
  projectConfig: ConfigInterface
}

function PanoramicView({ projectConfig }: PanoramicViewProps) {
  return projectConfig.type === ProjectType.HouseAndLand ? (
    <HouseAndLand />
  ) : (
    <Apartment />
  )
}

export default connect(({ projectConfig }: RootStateTypeExtra) => ({
  projectConfig,
}))(PanoramicView)

import React from 'react'
import { connect } from 'react-redux'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import {
  Channel,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import { hasToken } from '@utilities/helper'
import LightChannelResetter from '@utilities/lightmap-handler/light-channel-resetter'
import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { setBackground } from '@adUtilities/asset-handler/asset-util'

export interface ContainerProps {
  children?: React.ReactNode
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  token: TokenPayloadInterface
  channels: Array<Channel>
  style?: React.CSSProperties
  background?: {
    url: string
    type: 'legacy' | 'new'
    noSpliceUrl: boolean
    staticUrl?: boolean
  }
  gradiant?: number
  className?: string
  disableNavigation?: boolean
  fullScreenMode?: boolean
}

function Container({
  style,
  children,
  projectIdentity,
  session,
  token,
  channels,
  background,
  gradiant = 0.5,
  className = '',
  disableNavigation = false,
  fullScreenMode,
}: ContainerProps) {
  LightChannelResetter({ projectIdentity, channels })

  const remoteAppIsConnected = React.useMemo(
    () => hasToken(token) && session?.connected,
    [token, session]
  )

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })

  const [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger] =
    useMatrixCacheCleaner({
      projectName: projectIdentity.projectName,
      email: token.email,
    })

  const resetClearCacheTriggerKeyFromFirebaseAndReload = async () => {
    await firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: '',
      [`clearCacheKeyState`]: 'cleared',
    })
    window.location.reload()
  }

  React.useEffect(() => {
    if (isMatrixCacheCleaned) {
      resetClearCacheTriggerKeyFromFirebaseAndReload()
    }
  }, [isMatrixCacheCleaned])

  React.useEffect(() => {
    if (hasToken(token) && session) {
      const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session
      handleMatrixCacheCleanerTrigger(firebaseClearCacheTriggerKey !== '')
    }
  }, [session])

  return (
    <div
      className={`background-cover relative bg-secondaryColour transition-all duration-300 ${
        !disableNavigation && !(fullScreenMode || remoteAppIsConnected)
          ? 'top-9 h-page-container rounded-t-3xl ipad-mini:top-16'
          : 'top-0 h-screen'
      } ${className}`}
      style={{
        ...setBackground(background ? AssetHandler(background) : '', {
          gradiant,
        }),
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session, lightMap },
    token,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
    token,
    channels: lightMap?.channels || [],
  })
)(Container)

import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { RootStateTypeExtra, SessionMap } from '@store/types'

import { useIdleTimeHandler } from './utils'

export interface IdleTimeHandlerProps {
  children: React.ReactNode
  disable?: boolean
  className?: string
  session: SessionMap | undefined
  controls: {
    duration: number
    active: boolean
  }
}

function IdleTimeHandler({
  children,
  disable = false,
  className,
  session,
  controls,
}: IdleTimeHandlerProps) {
  const [isConnected, setIsConnected] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: controls.duration,
    isIdleActive: controls.active,
  })

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session?.connected])

  if (disable) return <div>{children}</div>

  return (
    <CSSTransition
      in={!isAppIdle}
      classNames="transition-fade-out"
      timeout={300}
    >
      <div className={className}>{children}</div>
    </CSSTransition>
  )
}

export default connect(
  ({ firestore: { session }, projectConfig }: RootStateTypeExtra) => ({
    session,
    controls: projectConfig.controls,
  })
)(IdleTimeHandler)
